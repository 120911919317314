import React, { useState } from "react"
import { DeviceHistoryControls } from "./DeviceHistoryControls"
import { LineChart } from "../Charts/LineChart"
import { BooleanChart } from "../Charts/BooleanChart"
import { BarChart } from "../Charts/BarChart"
import { ChartList } from "../Charts/ChartList"
import { useRoleContext } from "../RoleContext"
import { Role } from "../../enums/Role"
import { Col, Form, Row } from "react-bootstrap"
import { resolutions } from "../HistoryContext"

export const SystemGraphs = ({ systemId }) => {
    const { role } = useRoleContext()
    const analyticsAllowed = [Role.Admin, Role.Developer].includes(role)
    const [barRes, setBarRes] = useState(resolutions[resolutions.length - 1])

    const Controls = () => (
        <DeviceHistoryControls className="mb-3" allowResolutionSelection={false} />
    )

    const BarChartResolutionSelector = () => (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
                Bar chart resolution
            </Form.Label>
            <Col sm="10">
                <Form.Select
                    value={barRes}
                    onChange={(e) => {
                        setBarRes(e.target.value)
                    }}
                >
                    {resolutions
                        .filter((r) => r !== "automatic")
                        .map((res) => (
                            <option key={res}>{res}</option>
                        ))}
                </Form.Select>
            </Col>
        </Form.Group>
    )

    return (
        <ChartList systemId={systemId} barChartResolution={barRes}>
            <Controls />

            <h2>Ambient</h2>
            <LineChart
                chartId="ambient"
                chartIdentifier={systemId}
                values={[
                    { key: "ambient_eco2", hide: true },
                    { key: "ambient_humidity", hide: true },
                    "ambient_temperature",
                    { key: "room_setpoint", type: "event" },
                ]}
                baseValue={{
                    type: "continuous",
                }}
            />

            <Controls />

            <h2>Air</h2>
            <LineChart
                chartId="air"
                chartIdentifier={systemId}
                values={[
                    "air_outdoor_temperature",
                    "air_exhaust_temperature",
                    { key: "air_return_speed", hide: true },
                    "air_flow_setpoint",
                    "air_ducts_position",
                    "air_external_temperature",
                    "air_indoor_humidity",
                    "air_return_temperature",
                    "heat_pump_determined_air_indoor_valve_position",
                    "heat_pump_determined_air_outdoor_valve_position",
                ]}
                baseValue={{
                    type: "continuous",
                }}
            />

            <Controls />

            <h2>Water</h2>
            <LineChart
                chartId="water"
                chartIdentifier={systemId}
                values={[
                    "water_return_temperature",
                    "water_supply_temperature",
                    "central_heating_water_pressure",
                    "water_condenser_water_out_temperature",
                    "water_pump_flow",
                    "heat_pump_determined_water_pump_speed_setpoint",
                    "external_under_pressure",
                ]}
                baseValue={{
                    type: "continuous",
                }}
            />

            <Controls />

            <h2>Heat pump</h2>
            <LineChart
                chartId="heatpump"
                chartIdentifier={systemId}
                values={[
                    "heat_pump_hot_gas_temperature",
                    "heat_pump_condenser_refrigerant_out_temperature",
                    "heat_pump_evaporator_refrigerant_out_temperature",
                    "heat_pump_evaporation_pressure",
                    "heat_pump_condensation_pressure",
                    "heat_pump_air_exhaust_temperature",
                    // "heat_pump_current_compressor_speed",
                    "heat_pump_condensation_temperature",
                    "heat_pump_evaporation_temperature",
                ]}
                baseValue={{
                    type: "continuous",
                }}
            />

            <Controls />

            <h2>Status</h2>
            <BooleanChart
                chartId="status"
                chartIdentifier={systemId}
                showInts={true}
                values={[
                    "ventilation_level",
                    "flame_state",
                    "connected",
                    "heat_recovery_valve_central_heating_active",
                ]}
                baseValue={{
                    type: "event",
                }}
            />

            <Controls />

            <h2>Auxillary heating</h2>
            <BooleanChart
                chartId="auxillary-heating"
                chartIdentifier={systemId}
                values={[
                    "backup_heater_1_active",
                    "backup_heater_2_active",
                    "backup_heater_3_active",
                    "central_heating_enabled",
                ]}
                baseValue={{
                    type: "event",
                }}
            />

            {analyticsAllowed && (
                <>
                    <Controls />

                    <h2>Analytics temperature & flow</h2>
                    <LineChart
                        chartId="analytics-temperature-and-flow"
                        chartIdentifier={systemId}
                        values={[
                            "external_total_supply_temperature",
                            "external_total_return_temperature",
                            "external_aux_supply_temperature",
                            "external_aux_return_temperature",
                            "external_total_flow",
                            "external_aux_flow",
                        ]}
                        baseValue={{
                            type: "continuous",
                        }}
                    />

                    <Controls />

                    <h2>Analytics electric</h2>
                    <LineChart
                        chartId="analytics-electric"
                        chartIdentifier={systemId}
                        values={[
                            "external_voltage_l1",
                            "external_current_l1",
                            "external_voltage_l2",
                            "external_current_l2",
                            "external_voltage_l3",
                            "external_current_l3",
                            "external_current_n",
                        ]}
                        baseValue={{
                            type: "continuous",
                        }}
                    />

                    <Controls />

                    <h2>Analytics power</h2>
                    <LineChart
                        chartId="analytics-power"
                        chartIdentifier={systemId}
                        values={[
                            "external_electric_power_l1",
                            { key: "external_electric_power_l2", hide: true },
                            { key: "external_electric_power_l3", hide: true },
                            { key: "external_electric_power_total", hide: true },
                            "external_total_power",
                            "external_aux_power",
                            "calculated_central_heating_heat_pump_power",
                            "calculated_central_heating_aux_power",
                            "calculated_central_heating_aux_power_calculated",
                            "calculated_domestic_hot_water_heat_pump_power",
                            "calculated_domestic_hot_water_aux_power",
                            "calculated_total_power_calculated",
                        ]}
                        baseValue={{
                            type: "continuous",
                        }}
                    />

                    <Controls />

                    <h2>Analytics energy sensors</h2>
                    <LineChart
                        chartId="analytics-energy-sensors"
                        chartIdentifier={systemId}
                        values={[
                            "external_aux_energy",
                            "external_central_heating_aux_energy_delta",
                            "external_central_heating_heat_pump_energy_delta",
                            "external_domestic_hot_water_aux_energy_delta",
                            "external_domestic_hot_water_heat_pump_energy_delta",
                            "external_electric_energy_l1",
                            "external_electric_energy_l2",
                            "external_electric_energy_l3",
                            "external_electric_energy_total",
                            "external_energy_delta_total",
                            "external_total_heat_energy",
                        ]}
                        baseValue={{
                            type: "continuous",
                        }}
                    />

                    <Controls />
                    <hr />
                    <BarChartResolutionSelector />

                    <h2>Analytics energy</h2>
                    <BarChart
                        chartId="analytics-energy"
                        chartIdentifier={systemId}
                        values={[
                            { key: "calculated_electric_energy_l1", hide: true },
                            { key: "calculated_electric_energy_l2", hide: true },
                            { key: "calculated_electric_energy_l3", hide: true },
                            "calculated_electric_energy_total",
                            "calculated_central_heating_aux_energy",
                            "calculated_central_heating_aux_energy_calculated",
                            "calculated_total_energy",
                            "calculated_total_energy_calculated",
                            "calculated_central_heating_heat_pump_energy",
                            "calculated_domestic_hot_water_aux_energy",
                            "calculated_domestic_hot_water_aux_energy_calculated",
                            "calculated_domestic_hot_water_heat_pump_energy",
                        ]}
                        baseValue={{
                            type: "bar",
                        }}
                    />

                    <Controls />

                    <h2>Analytics COP</h2>
                    <BarChart
                        chartId="analytics-cop"
                        chartIdentifier={systemId}
                        values={["calculated_cop_heat_pump", "calculated_cop_total"]}
                        baseValue={{
                            type: "bar",
                        }}
                    />
                </>
            )}
        </ChartList>
    )
}

// Not included
// "air_as200_sensor_state",
// "air_fan_speed",
// "external__domestic_hot_water_aux_energy_delta",
// "heat_pump_desired_air_indoor_valve_setpoint",
// "heat_pump_desired_air_outdoor_valve_setpoint",
// "heat_pump_desired_compressor_speed",
// "heat_pump_desired_reverse_cycle_position_is_cooling",
// "heat_pump_determined_air_fan_speed_setpoint",
// "heat_pump_determined_compressor_speed",
// "heat_pump_determined_superheat_expansion_valve_position",
// "heat_pump_inverter_fault_code",
// "heat_pump_inverter_input_power",
// "heat_pump_maximum_compressor_speed_envelope",
// "heat_pump_maximum_compressor_speed_hotgas",
// "heat_pump_minimum_compressor_speed_envelope",
// "performance_energy_consumption_l1",
// "performance_energy_consumption_l2",
// "performance_energy_consumption_l3",
// "performance_power_usage_l1",
// "performance_power_usage_l2",
// "performance_power_usage_l3",
// "water_flow",
// "water_open_manifold_temperature",
// "water_pump_status",
