import { useAuth0 } from "@auth0/auth0-react"
import { ApiClient } from "../../ApiClient"
import { ReactSelect } from "../ReactSelect"
import { AsyncPaginate } from "react-select-async-paginate"

export const FirmwareSelector = ({
    value,
    setValue,
    onlyDeviceOfType,
    onlyFirmwareOfType,
    currentFirmwareId,
    firmwareType,
    isMulti,
    firmwareFilter = () => true,
}) => {
    const { getAccessTokenSilently } = useAuth0()

    const loadOptions = async (inputValue, _options, { page }) => {
        const pageSize = 10
        const filters = [[["name", "like", inputValue], ["OR"], ["version", "like", inputValue]]]
        if (onlyDeviceOfType !== undefined) {
            filters.push(["AND"])
            filters.push(["device_type", "=", onlyDeviceOfType])
        }

        if (firmwareType !== undefined) {
            filters.push(["AND"])
            filters.push(["type", "=", firmwareType])
        }

        const token = await getAccessTokenSilently()
        const { data } = await ApiClient.get(
            "/v2/management/firmwares" +
                `?size=${pageSize}` +
                `&page=${page}` +
                `&filters=${JSON.stringify(filters)}`,
            {
                Authorization: `Bearer ${token}`,
            }
        )

        let firmwares = data.firmwares
        if (currentFirmwareId) {
            firmwares = firmwares.filter((fw) => fw.firmware_id !== currentFirmwareId)
        }

        return {
            options: firmwares.filter(firmwareFilter).map((f) => ({
                label: firmwareToLabel(f, onlyDeviceOfType, onlyFirmwareOfType),
                value: f,
            })),
            hasMore: data.total_count > (page + 1) * pageSize,
            additional: { page: page + 1 },
        }
    }

    return (
        <>
            <ReactSelect
                tag={AsyncPaginate}
                debounceTimeout={500}
                additional={{ page: 0 }}
                isMulti={isMulti}
                value={value}
                loadOptions={loadOptions}
                onChange={setValue}
                formatOptionLabel={(firmware) => (
                    <>
                        <span>
                            <b>{firmware.value.name || firmware.value.version}</b>
                        </span>
                        {onlyDeviceOfType === undefined && (
                            <span>{firmware.value.device_type?.toLowerCase()}</span>
                        )}
                        {firmwareType && firmwareType === "BOARDVERSION" && (
                            <span>{firmware.value.board_type?.toLowerCase()}</span>
                        )}
                        {onlyFirmwareOfType === undefined && (
                            <span>{firmware.value.type?.toLowerCase()}</span>
                        )}
                    </>
                )}
            />
            {/* This hidden input is only used in the tests,
                as a way to get the selected firmware id */}
            <input
                name="react-select-hidden"
                type="hidden"
                value={
                    value.value !== undefined
                        ? value.value.firmware_id
                        : value.map((fw) => fw.value.firmware_id).join()
                }
            />
        </>
    )
}

const firmwareToLabel = (firmware, onlyDeviceOfType) => {
    let deviceType = onlyDeviceOfType === undefined ? ` (${firmware.device_type})` : ""
    let boardType = firmware.board_type === undefined ? "" : ` (${firmware.board_type})`
    return firmware.name !== undefined
        ? `${firmware.name}${deviceType} (group)`
        : `${firmware.version}${deviceType}${boardType} (version)`
}
