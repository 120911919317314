import { Badge, ListGroup, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"

export const FirmwareChain = ({ firmware, doneLoading }) =>
    !doneLoading ? (
        <Spinner variant="primary" />
    ) : firmware ? (
        <ListGroup variant="flush">
            {flattenFirmwares(firmware).map((fw) => (
                <Item
                    key={fw.firmware_id}
                    fw={fw}
                    isAttached={firmware.firmware_id === fw.firmware_id}
                />
            ))}
        </ListGroup>
    ) : (
        "No linked firmware version or device groups."
    )

const mapFirmwareName = (type) => {
    switch (type) {
        case "VERSION":
            return "Version"
        case "SET":
            return "Set"
        case "BOARDVERSION":
            return "Board"
        case "GROUP":
            return "Group"
        default:
            return "Unknown"
    }
}

const fwContent = (fw) => {
    switch (fw.type) {
        case "BOARDVERSION":
            return (
                <>
                    {fw.board_type} <br /> {fw.version} <br /> {fw.uri}
                </>
            )
        case "VERSION":
            return (
                <>
                    {fw.version} <br /> {fw.uri}
                </>
            )
        case "GROUP":
            return fw.name
        case "SET":
            return fw.version
        default:
            return "INVALID"
    }
}

const Item = ({ fw, isAttached }) => (
    <ListGroup.Item
        action
        as={Link}
        to={(fw.type === "GROUP" ? "/devicegroup/" : "/firmware/") + fw.firmware_id}
        className="d-flex justify-content-between align-items-start text-muted"
    >
        <div className="fw-bold" style={{ minWidth: "80px" }}>
            {mapFirmwareName(fw.type)}
        </div>
        <div className="text-break me-auto">{fwContent(fw)}</div>
        {isAttached && <Badge bg="primary">Directly attached</Badge>}
    </ListGroup.Item>
)

export const flattenFirmwares = (firmware) => {
    if (!firmware) {
        return []
    }

    if (!firmware.children || firmware.children.length === 0) {
        return [firmware]
    }

    return [firmware, ...firmware.children.flatMap((child) => flattenFirmwares(child))]
}
