import { useEffect, useState } from "react"
import { Form, InputGroup } from "react-bootstrap"

const versionRegex = /^\d+$/,
    tagRegex = /^[0-9A-Za-z-.]*$/

export const FirmwareVersionInput = ({ setVersion }) => {
    const [major, setMajor] = useState("0")
    const [minor, setMinor] = useState("0")
    const [patch, setPatch] = useState("0")
    const [tag, setTag] = useState("")

    const majorValid = versionRegex.test(major)
    const minorValid = versionRegex.test(minor)
    const patchValid = versionRegex.test(patch)
    const tagValid = tagRegex.test(tag)
    const versionValid =
        majorValid && minorValid && patchValid && tagValid && major + minor + patch > 0

    const version = tag ? `${major}.${minor}.${patch}-${tag}` : `${major}.${minor}.${patch}`
    useEffect(() => setVersion(versionValid ? version : ""), [setVersion, versionValid, version])

    return (
        <InputGroup>
            <Form.Control
                isInvalid={!majorValid}
                style={{ maxWidth: "120px" }}
                type="number"
                placeholder="major"
                min={0}
                step={1}
                onChange={(e) => setMajor(e.target.value)}
            />
            <Form.Control
                isInvalid={!minorValid}
                style={{ maxWidth: "120px" }}
                type="number"
                placeholder="minor"
                min={0}
                step={1}
                onChange={(e) => setMinor(e.target.value)}
            />
            <Form.Control
                isInvalid={!patchValid}
                style={{ maxWidth: "120px" }}
                type="number"
                placeholder="patch"
                min={0}
                step={1}
                onChange={(e) => setPatch(e.target.value)}
            />
            <Form.Control
                isInvalid={!tagValid}
                type="text"
                placeholder="tag"
                onChange={(e) => setTag(e.target.value)}
            />
            <InputGroup.Text>{version}</InputGroup.Text>
        </InputGroup>
    )
}
