/**
 * React Remote Sortable Columns
 * adapted from https://www.npmjs.com/package/react-remote-sortable-columns
 */

import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

class SortableTr extends Component {
    handleClick(key) {
        let { sortKey, sortDir } = this.props
        if (sortKey === key) {
            this.props.onChange(key, sortDir === "desc" ? "asc" : "desc")
        } else {
            this.props.onChange(key, "asc")
        }
    }

    render() {
        const {
            children,
            sortKey,
            sortDir,
            descClass = "fa-solid fa-sort-down",
            ascClass = "fa-solid fa-sort-up",
        } = this.props

        let childrenWithProps = React.Children.map(children, (child) => {
            return child && child.type === SortableTh
                ? React.cloneElement(child, {
                      handleClick: this.handleClick.bind(this),
                      sortKey: sortKey,
                      sortDir: sortDir,
                      descClass,
                      ascClass,
                  })
                : child
        })

        return <tr>{childrenWithProps}</tr>
    }
}

SortableTr.propTypes = {
    sortDir: PropTypes.string,
    sortKey: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    descClass: PropTypes.string,
    ascClass: PropTypes.string,
}

const SortableTh = ({
    sortKey,
    sortDir,
    children,
    handleClick,
    column,
    descClass,
    ascClass,
    ...props
}) => {
    const icon = sortDir === "desc" ? descClass : ascClass

    return column ? (
        <th
            {...props}
            className={classNames("pointer", props.className)}
            onClick={() => handleClick(column)}
        >
            {children}
            {sortKey === column && <i className={classNames("ms-2", icon)}></i>}
        </th>
    ) : (
        <th {...props}>{children}</th>
    )
}

SortableTh.propTypes = {
    column: PropTypes.string,
}

export { SortableTh, SortableTr }
