import React, { useEffect, useState, useRef } from "react"

import { ApiClient } from "../../ApiClient"
import { useParams } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { Button, FormLabel } from "react-bootstrap"
import { MessageType, useMessageReport } from "../MessageReporter"

export const DeviceFirmwareLink = () => {
    const { addMessage } = useMessageReport()

    const [failedDevices, setFailedDevices] = useState([])
    const [disableLinkDevices, setDisableLinkDevices] = useState(false)

    const serialRefs = useRef()
    const { id } = useParams()

    const { getAccessTokenSilently } = useAuth0()

    const resetInput = () => {
        Array.from(serialRefs.current.children).forEach((div) => {
            div.style.color = "inherit"
        })
    }

    useEffect(() => {
        if (failedDevices.length === 0) {
            return
        }

        Array.from(serialRefs.current.children).forEach((div) => {
            const sn = div.innerText.trim()

            if (failedDevices.includes(sn)) {
                div.style.color = "red"
            } else {
                div.style.color = "inherit"
            }
        })
    }, [failedDevices])

    const linkDevices = () => {
        setDisableLinkDevices(true)
        const parent = serialRefs.current
        const contents = parent.innerText

        Array.from(parent.children).forEach((div) => {
            div.remove()
        })

        parent.innerText = ""

        contents.split("\n").forEach((sn) => {
            let div = document.createElement("div")
            div.innerText = sn
            parent.appendChild(div)
        })

        const serialNumbers = Array.from(parent.children).map((div) => div.innerText.trim())

        getAccessTokenSilently()
            .then((token) =>
                ApiClient.patch(`/v2/management/firmwares/${id}/link/`, serialNumbers, {
                    Authorization: "Bearer " + token,
                }).then((res) => {
                    setFailedDevices(res.data.devices_failed)
                    if (res.data.devices_failed.length === 0) {
                        addMessage(
                            "Linked devices",
                            "Successfully linked all devices",
                            MessageType.success
                        )
                    }
                })
            )
            .catch((e) => {
                addMessage(
                    "Failed to link firmware",
                    "Something went wrong while linking the firmware to the devices.",
                    MessageType.error,
                    e
                )
                setFailedDevices(serialNumbers)
            })
            .finally(setDisableLinkDevices(false))
    }

    return (
        <>
            <h6 className="heading-small text-muted mb-4">Device linking</h6>
            <FormLabel>Devices</FormLabel>
            <div
                className="editable_div_bootstrap devices_link"
                ref={serialRefs}
                contentEditable="true"
                onInput={resetInput}
            ></div>
            <Button
                className="mt-4 link"
                disabled={disableLinkDevices}
                onClick={linkDevices}
                type="button"
            >
                Link devices
            </Button>
        </>
    )
}
