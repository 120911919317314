import React from "react"
import { SortableTh } from "../../components/SortableColumns"
import { DetailLink } from "../../components/Table/DetailLink"
import { GenericOverview } from "../../components/Table/GenericOverview"
import { Link, useNavigate } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { formatDateTime } from "../../util/formatDataTime"

export const UserOverview = () => {
    const endpoint = "/v2/consumer/users"

    const jsonDataField = (setData, data) => setData(data.users)
    const navigate = useNavigate()

    const filter = {
        initial: [],
        onSearch: (filters, searchText) => {
            filters.push(["email", "like", searchText])
            filters.push(["OR"])
            filters.push(["role", "like", searchText])
            return filters
        },
    }

    const error = {
        title: "Failed to get users",
        body: "Something went wrong while getting the users, see the console for more details.",
    }

    const table = {
        header: [
            <SortableTh column="email">Email</SortableTh>,
            <SortableTh column="role">Role</SortableTh>,
            <SortableTh column="last_auth">Last activity</SortableTh>,
            <th>Actions</th>,
        ],
        body: (value) => (
            <tr
                key={value.id}
                onClick={() => {
                    navigate(`/user/${value.id}`)
                }}
            >
                <td>{value.email}</td>
                <td>{value.role}</td>
                <td>{formatDateTime(value.last_auth)}</td>
                <td>
                    <DetailLink to={`/user/${value.id}`} />
                </td>
            </tr>
        ),
    }

    return (
        <Row className="content-section">
            <Col>
                <h2 className="float-start">Users</h2>
                <Link className="btn btn-success float-end mb-3" to="/user/add">
                    Add user
                </Link>
                <GenericOverview
                    apiEndpoint={endpoint}
                    dataField={jsonDataField}
                    filter={filter}
                    error={error}
                    table={table}
                />
            </Col>
        </Row>
    )
}
