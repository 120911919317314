import { useState } from "react"
import { MessageType, useMessageReport } from "../MessageReporter"
import { Col, Form, Row } from "react-bootstrap"
import { ReactSelect } from "../ReactSelect"

const deviceListUrl = process.env.REACT_APP_DEVICE_LIST_URL
const deviceListUrlV2 = process.env.REACT_APP_DEVICE_LIST_URL_V2

export const FirmwareUriBoardTypeInput = ({ setFirmware }) => {
    const { addMessage } = useMessageReport()

    const [url, setUrl] = useState({
        cert: "v2",
        env: "...",
        device: "...",
        board: "...",
        uri: "...",
    })

    const boardTypes = {
        BEN: ["HPH200", "HPC200", "HPP200", "ZIGBEE"],
    }

    const envOptions = ["prd", "dev"]
    const [deviceOptions, setDeviceOptions] = useState([])
    const [boardOptions, setBoardOptions] = useState([])
    const [uriOptions, setUriOptions] = useState([])

    const onEnvChange = (value) => {
        url.env = value
        url.device = "..."
        url.uri = "..."
        url.board = "..."
        setUrl({ ...url })
        getFirmwareOptions(addMessage, url).then((devs) => {
            const categorizedDevices = Object.keys(boardTypes).reduce((acc, key) => {
                acc[key] = devs.filter((dev) => boardTypes[key].includes(dev.name.toUpperCase()))
                return acc
            }, {})

            const allMatchedDevices = Object.values(categorizedDevices).flat()

            const otherDevices = devs.filter((dev) => !allMatchedDevices.includes(dev))

            const mapped = Object.keys(categorizedDevices).map((dev) => {
                return { name: dev }
            })

            const result = [...mapped, ...otherDevices]

            setDeviceOptions(result)
        })
        setFirmware({ device: "", uri: "", boardtype: "" })
    }
    const onDeviceChange = (value) => {
        url.device = value
        url.uri = "..."
        url.board = "..."
        setUrl({ ...url })

        setBoardOptions(boardTypes[url.device])
        setFirmware({ board: "", uri: "" })
    }

    const onBoardChange = (value) => {
        url.uri = "..."
        url.board = value
        setUrl({ ...url })

        getFirmwareOptions(addMessage, url)
            .then((bins) =>
                setUriOptions(
                    bins.map((u) => ({
                        label: u.name,
                        value: u.name,
                    }))
                )
            )
            .catch((e) => {
                addMessage(
                    "Failed to get binaries",
                    "No binaries seem to exist for this board type."
                )
            })

        setFirmware({ board: value, uri: "" })
    }

    const onUriChange = (value) => {
        url.uri = value
        setUrl({ ...url })
        setFirmware({
            device: url.device.toUpperCase(),
            boardtype: url.board,
            uri: urlToString(url),
        })
    }

    return (
        <Row className="mb-3">
            <Col xs={12} md={2}>
                <Form.Group>
                    <Form.Label>Environment</Form.Label>
                    <Form.Select
                        disabled={url.cert === "..."}
                        onChange={(e) => onEnvChange(e.target.value)}
                        value={url.env}
                    >
                        <option disabled>...</option>
                        {envOptions.map((type) => (
                            <option key={type}>{type}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col xs={12} md={2}>
                <Form.Group>
                    <Form.Label>Device type</Form.Label>
                    <Form.Select
                        disabled={url.env === "..."}
                        onChange={(e) => onDeviceChange(e.target.value)}
                        value={url.device}
                    >
                        <option disabled>...</option>
                        {deviceOptions.map((dev) => (
                            <option key={dev.name}>{dev.name}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col xs={12} md={2}>
                <Form.Group>
                    <Form.Label>Board type</Form.Label>
                    <Form.Select
                        disabled={url.device === "..."}
                        onChange={(e) => onBoardChange(e.target.value)}
                        value={url.board}
                    >
                        <option disabled>...</option>
                        {boardOptions?.map((boardtype) => (
                            <option key={boardtype}>{boardtype}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col xs={12} md={6}>
                <Form.Group>
                    <Form.Label>Uri</Form.Label>
                    <ReactSelect
                        isDisabled={url.board === "..."}
                        value={url.uri}
                        options={uriOptions}
                        onChange={onUriChange}
                    />
                </Form.Group>
            </Col>
            <Form.Text muted className="text-end">
                {urlToString(url)}
            </Form.Text>
        </Row>
    )
}

const urlToString = (url) => {
    let str = ""

    if (url.cert === "v1") {
        str += deviceListUrl
    }
    if (url.cert === "v2") {
        str += deviceListUrlV2
    }

    if (url.env === "dev") {
        str += "dev/"
    }

    if (url.board !== "...") {
        str += url.board.toLowerCase() + "/"
    }

    if (url.uri !== "...") {
        str += url.uri.value
    }

    return str
}

const getFirmwareOptions = (addMessage, url) =>
    fetch(urlToString(url))
        .then((response) => response.json())
        .then((data) => data.filter((e) => e.name !== "dev" && e.name !== "nightly"))
        .catch((e) => {
            addMessage(
                "Failed to get device types",
                <>
                    Something went wrong while getting the firmware, see the console for more
                    details.
                    <br />
                    <br />
                    You can probably solve this problem by going to{" "}
                    <a target="_blank" rel="noreferrer" href={urlToString(url)}>
                        {urlToString(url)}
                    </a>{" "}
                    and accepting the certificate.
                </>,
                MessageType.error,
                e
            )
        })
