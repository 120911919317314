import React, { useCallback, useContext, useState } from "react"
import { Toast, ToastContainer } from "react-bootstrap"

const MessageContext = React.createContext({
    messages: [],
    addMessage: () => {},
    hideMessage: () => {},
})

export const MessageType = {
    success: "success",
    error: "error",
}

export const MessageReporterProvider = ({ children }) => {
    const [messages, setMessages] = useState([])

    const hideMessage = useCallback(
        (key) => {
            setMessages((messages) => [...messages.filter((message) => message.key !== key)])
        },
        [setMessages]
    )

    const addMessage = useCallback(
        (name, msg, type, err) => {
            const key = crypto.randomUUID()

            // eslint-disable-next-line no-console
            console.warn(err)
            setMessages((existingErrors) => [
                ...existingErrors,
                {
                    name: name,
                    message: msg,
                    type: type,
                    key: key,
                },
            ])

            if (type === MessageType.success) {
                setTimeout(() => {
                    hideMessage(key)
                }, 3000)
            }
        },
        [setMessages, hideMessage]
    )

    return (
        <MessageContext.Provider
            value={{ messages: messages, addMessage: addMessage, hideMessage: hideMessage }}
        >
            {children}
        </MessageContext.Provider>
    )
}

export const useMessageReport = () => ({
    addMessage: useContext(MessageContext).addMessage,
})

export const MessageReporter = () => {
    const { messages: errors, hideMessage } = useContext(MessageContext)

    const backgrounds = {
        [MessageType.error]: "danger",
        [MessageType.success]: "success",
    }

    return (
        <ToastContainer position="bottom-end" className="p-3" style={{ position: "fixed" }}>
            {errors.map(({ key, name, message, type }) => (
                <Toast key={key} onClose={() => hideMessage(key)}>
                    <Toast.Header>
                        <span
                            className={`rounded me-2 bg-${
                                backgrounds[type] ? backgrounds[type] : "warning"
                            } ratio ratio-1x1`}
                            style={{ width: "21px" }}
                        />
                        <strong className="me-auto">{name}</strong>
                    </Toast.Header>
                    <Toast.Body>{message}</Toast.Body>
                </Toast>
            ))}
        </ToastContainer>
    )
}
