import React, { useEffect, useMemo, useState } from "react"
import { HistoryContextProvider, useHistory } from "../HistoryContext"
import { differenceInMilliseconds, milliseconds } from "date-fns"
import { MessageType, useMessageReport } from "../MessageReporter"
import { useAuth0 } from "@auth0/auth0-react"
import { ApiClient } from "../../ApiClient"

/**
 * If a direct child of ChartList has a `values` property, then those values will
 * be used to get history datapoints. The `values` property must be an array, the
 * items will be transformed according to the rules below. After transformation
 * the items must be of the type `{ key: string, type: 'continuous' | 'event' }`,
 * any excess properties are passed through and ignored.
 *
 * Transformation rules:
 *  - If the array item is a string it will be changed to `{ key: "foo" }`.
 *  - If the child has a baseValue property the array item will be merged into
 *    that value.
 *
 * Values with an `event` type will be retrieved with their original resolution.
 * Values with a `continuous` type with be retrieved with a resolution determined
 * by the selected time duration.
 *
 * The child will be given a new propery: `data`. Which will initially be an empty
 * object, and will be set to the historical datapoints specified in `values`
 * after they have been retrieved.
 *
 * The child will also be given the properties: loading.
 * Loading is a boolean signifying whether the data is being loaded.
 */
export const ChartList = ({ children, systemId, barChartResolution }) => (
    <HistoryContextProvider>
        <ChartListInternal
            children={children}
            systemId={systemId}
            barChartResolution={barChartResolution}
        />
    </HistoryContextProvider>
)

const ChartListInternal = ({ children, systemId, barChartResolution }) => {
    const { addMessage } = useMessageReport()
    const { getAccessTokenSilently } = useAuth0()
    const { start, end, resolution: overrideRes } = useHistory()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})

    const { resolution: calcRes } = settingFromPeriod(start, end)
    const startStr = start.toISOString()
    const endStr = end.toISOString()
    const resolution = useMemo(
        () => (overrideRes === "automatic" ? calcRes : overrideRes),
        [overrideRes, calcRes]
    )
    const mappedChildren = useMemo(
        () => children.flatMap((c) => (c.type === React.Fragment ? c.props.children : [c])),
        [children]
    )

    const valuesByChild = useMemo(
        () =>
            mappedChildren
                .map((c, i) => ({
                    index: i,
                    values: c.props?.values?.map((v) => normalizeValue(c.props.baseValue, v)),
                }))
                .filter((c) => c.values !== undefined),
        [mappedChildren]
    )

    const dataByChild = useMemo(
        () =>
            Object.fromEntries(
                valuesByChild.map(({ index, values }) => [
                    index,
                    Object.fromEntries(
                        values.filter(({ key }) => !!data[key]).map(({ key }) => [key, data[key]])
                    ),
                ])
            ),
        [data, valuesByChild]
    )

    const eventUrl = useMemo(
        () => makeUrl("event", "original", systemId, startStr, endStr, valuesByChild),
        [startStr, endStr, systemId, valuesByChild]
    )
    const continuousUrl = useMemo(
        () => makeUrl("continuous", resolution, systemId, startStr, endStr, valuesByChild),
        [resolution, startStr, endStr, systemId, valuesByChild]
    )
    const barUrl = useMemo(() => {
        const barStart = new Date(startStr)
        const barEnd = new Date(endStr)
        return makeUrl(
            "bar",
            barChartResolution,
            systemId,
            new Date(barStart.valueOf() - barStart.getTimezoneOffset() * 60 * 1000).toISOString(),
            new Date(barEnd.valueOf() - barEnd.getTimezoneOffset() * 60 * 1000).toISOString(),
            valuesByChild
        )
    }, [startStr, endStr, systemId, valuesByChild, barChartResolution])

    useEffect(() => {
        setLoading(true)
        const abortController = new AbortController()

        getAccessTokenSilently()
            .then((token) =>
                Promise.all(
                    [eventUrl, continuousUrl, barUrl]
                        .filter((url) => url !== "")
                        .map((url) =>
                            ApiClient.get(
                                url,
                                { Authorization: "Bearer " + token },
                                abortController.signal
                            )
                        )
                )
            )
            .then((results) =>
                Object.assign(
                    {},
                    ...results
                        .flatMap((res) => res.data)
                        .flatMap((data) => data.history)
                        .filter((items) => items && Object.keys(items).length > 0)
                )
            )
            .then(setData)
            .then(() => setLoading(false))
            .catch((err) => {
                if (String(err).includes("abort")) return
                addMessage("History", "Could not get history", MessageType.error, err)
                setLoading(false)
            })

        return () => abortController.abort("abort")
    }, [addMessage, getAccessTokenSilently, continuousUrl, eventUrl, barUrl])

    return useMemo(
        () =>
            React.Children.map(mappedChildren, (child, i) =>
                React.isValidElement(child) && child.props.values !== undefined
                    ? React.cloneElement(child, {
                          values: valuesByChild.filter((v) => v.index === i).pop()?.values,
                          data: dataByChild[i] ?? {},
                          loading: loading,
                      })
                    : child
            ),
        [dataByChild, loading, mappedChildren, valuesByChild]
    )
}

const makeUrl = (type, resolution, systemId, start, end, valuesByChild) => {
    const values = valuesByChild
        .flatMap((c) => c.values)
        .filter((v) => v.type === type)
        .map((v) => v.key)
        .join(",")

    if (values.length < 1) {
        return ""
    }

    return (
        `/v2/consumer/history/system` +
        `/${systemId}` +
        `/${start}` +
        `/${end}` +
        `/${resolution}` +
        `?filter=${values}`
    )
}

const normalizeValue = (baseValue, value) => {
    if (value === undefined) {
        return undefined
    }

    const normalized = { ...baseValue }

    if (value.type) {
        normalized.type = value.type
    }

    if (typeof value === "string") {
        normalized.key = value
        return { ...normalized }
    }
    if (value.key !== undefined && typeof value.key === "string") {
        return { ...normalized, ...value }
    }
    return undefined
}

const settings = [
    {
        duration: { minutes: 1 },
        format: "HH:mm",
        unit: "minute",
        resolution: "second",
        maxTicks: 10,
    },
    {
        duration: { minutes: 10 },
        format: "HH:mm",
        unit: "minute",
        resolution: "minute",
        maxTicks: 20,
    },
    {
        duration: { days: 1, minutes: -1 },
        format: "HH:mm",
        unit: "hour",
        resolution: "minute",
        maxTicks: 12,
    },
    {
        duration: { days: 2 },
        format: "dd/MM",
        unit: "day",
        resolution: "hour",
        maxTicks: 100,
    },
    {
        duration: { months: 1 },
        format: "dd/MM",
        unit: "day",
        resolution: "day",
        maxTicks: 16,
    },
    {
        duration: { years: 1, days: -3 },
        format: "MM",
        unit: "month",
        resolution: "day",
        maxTicks: 100,
    },
]
export const settingFromPeriod = (start, end) => {
    const range = differenceInMilliseconds(end, start)

    let setting = settings[0]
    for (let i = 0; i < settings.length; i++) {
        const settingRange = milliseconds(settings[i].duration)
        if (settingRange <= range) {
            setting = settings[i]
        }
    }
    return setting
}
