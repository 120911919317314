import { useAuth0 } from "@auth0/auth0-react"
import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { ApiClient } from "../../ApiClient"
import { MessageType, useMessageReport } from "../../components/MessageReporter"
import { useParams } from "react-router-dom"
import { FirmwareBoardversions } from "../../components/Firmware/FirmwareBoardversions"

export const FirmwareDetail = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()
    const { id } = useParams()

    const [uri, setUri] = useState("...")
    const [deviceType, setDeviceType] = useState("...")
    const [version, setVersion] = useState("...")
    const [boardType, setBoardType] = useState()
    const [fwType, setFwType] = useState()
    const [entries, setEntries] = useState([])

    useEffect(() => {
        getAccessTokenSilently()
            .then((token) =>
                ApiClient.get("/v2/management/firmwares/" + id, {
                    Authorization: "Bearer " + token,
                })
            )
            .then(({ data }) => {
                setVersion(data.version)
                setDeviceType(data.device_type)
                setUri(data.uri)
                setBoardType(data.board_type)
                setFwType(data.type)
                setEntries(data.children)
            })
            .catch((e) => {
                addMessage(
                    "Failed to load firmware",
                    "Something went wrong while loading the firmware, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })
    }, [getAccessTokenSilently, id, addMessage])

    return (
        <Row className="content-section">
            <Col xs={12}>
                <h2>Firmware version details</h2>
                <Form.Group className="mb-3">
                    <Form.Label>Firmware ID</Form.Label>
                    <Form.Control readOnly disabled type="text" value={id} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Version</Form.Label>
                    <Form.Control readOnly disabled type="text" value={version} />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3">
                    <Form.Label>Device type</Form.Label>
                    <Form.Control disabled value={deviceType} />
                </Form.Group>
            </Col>
            {boardType && (
                <Col xs={12} md={3}>
                    <Form.Group className="mb-3">
                        <Form.Label>Board type</Form.Label>
                        <Form.Control disabled value={boardType} />
                    </Form.Group>
                </Col>
            )}
            {fwType !== "SET" && (
                <Col xs={12} md={9}>
                    <Form.Group className="mb-3">
                        <Form.Label>Uri</Form.Label>
                        <Form.Control disabled value={uri} />
                    </Form.Group>
                </Col>
            )}
            {fwType === "SET" && <FirmwareBoardversions entries={entries} />}
        </Row>
    )
}
