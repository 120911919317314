import classNames from "classnames"
import { Bar } from "react-chartjs-2"
import { filterToLabel, lineColors } from "./LineChart"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { settingFromPeriod } from "./ChartList"
import { useHistory } from "../HistoryContext"
import { Spinner } from "react-bootstrap"

export const BarChart = ({ values, data, loading, chartIdentifier, chartId }) => {
    const { start, end, addGraphData } = useHistory()
    const setting = settingFromPeriod(start, end)
    const chartRef = useRef(null)

    const chartData = useMemo(
        () => ({
            datasets: Object.entries(data).map(([key, val], i) => ({
                key: key,
                label: filterToLabel(key, val),
                data: val.data.map((v) => ({ x: new Date(v.timestamp), y: v.value })),
                backgroundColor: lineColors[i],
                hidden: values.filter((v) => v.key === key).pop().hide,
            })),
        }),
        [values, data]
    )

    const setChartData = useCallback(
        (chart) => {
            if (!chart) {
                return
            }

            const chartId = chart.canvas.id
            const data = chart._metasets
                .filter((set) => set.visible)
                .flatMap((item) =>
                    item._dataset.data.map((datapoint) => ({
                        timestamp: datapoint.x.toISOString(),
                        datapointType: item.label,
                        value: datapoint.y,
                    }))
                )

            addGraphData(chartIdentifier, chartId, data)
        },
        [addGraphData, chartIdentifier]
    )

    useEffect(() => {
        setChartData(chartRef.current)
    }, [setChartData, loading, data])

    const options = baseOptions()

    options.scales.x.time.unit = setting.unit
    options.plugins.legend.onClick = (e, legendItem, legend) => {
        const meta = legend.chart.getDatasetMeta(legendItem.datasetIndex)
        // Update label
        meta.hidden = meta.visible
        legend.chart.update()

        setChartData(legend.chart)
    }
    options.scales.x.time.displayFormats = {
        minute: setting.format,
        hour: setting.format,
        day: setting.format,
        week: setting.format,
        month: setting.format,
        year: setting.format,
    }

    return (
        <div className={classNames("history-graph", { loading: loading })}>
            <Bar id={chartId} options={options} ref={chartRef} data={chartData} />
            <div>
                <Spinner variant="primary" />
            </div>
        </div>
    )
}

const baseOptions = () => ({
    responsive: true,
    datasets: {},
    scales: {
        x: {
            type: "time",
            time: {
                tooltipFormat: "dd-MM-yyyy HH:mm:ss",
            },
            ticks: {
                source: "data",
            },
        },
    },
    plugins: {
        legend: {
            position: "bottom",
            labels: {
                usePointStyle: true,
            },
        },
    },
})
