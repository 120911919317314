import { Line } from "react-chartjs-2"

export const StatisticChart = ({ data }) => {
    const options = {
        responsive: true,
        elements: {
            point: {
                radius: 1,
            },
        },
        scales: {
            x: {
                type: "time",
            },
            y: {
                min: 0,
                ticks: {
                    precision: 0,
                },
            },
        },
    }
    return <Line data={data} options={options} />
}
