import { Spinner, Stack } from "react-bootstrap"
import { Link } from "react-router-dom"

export const DeviceTree = ({ device }) =>
    !device.device_id ? (
        <Spinner variant="primary" />
    ) : device.attached_devices && device.attached_devices.length > 0 ? (
        renderDeviceTree(device.attached_devices)
    ) : (
        "This device does not have any attached devices."
    )

const renderDeviceTree = (devices) => (
    <ul>
        {devices.map((device) => (
            <li key={device.device_id} className="mb-2">
                <Stack direction="horizontal">
                    <div className="me-2">
                        SN:
                        <br />
                        Type:
                    </div>
                    <div>
                        <Link to={`/device/${device.device_id}`}>{device.sn}</Link>
                        <br />
                        {device.type}
                    </div>
                </Stack>
                {renderDeviceTree(device.attached_devices)}
            </li>
        ))}
    </ul>
)
