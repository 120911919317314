const BASE_URL = process.env.REACT_APP_API_URL
const defaultHeaders = {
    "X-App-Name": "admin-dashboard",
}

const get = (url, headers = [], signal) =>
    fetch(BASE_URL + url, {
        method: "GET",
        headers: { ...defaultHeaders, ...headers },
        signal: signal,
    }).then(handleResponse)

const post = (url, body, headers = []) =>
    fetch(BASE_URL + url, {
        method: "POST",
        headers: { "Content-Type": "application/json", ...defaultHeaders, ...headers },
        body: JSON.stringify(body),
    }).then(handleResponse)

const put = (url, body, headers = []) =>
    fetch(BASE_URL + url, {
        method: "PUT",
        headers: { "Content-Type": "application/json", ...defaultHeaders, ...headers },
        body: JSON.stringify(body),
    }).then(handleResponse)

// prefixed with underscore because delete is a reserved word in javascript
const _delete = (url, headers = []) =>
    fetch(BASE_URL + url, {
        method: "DELETE",
        headers: { ...defaultHeaders, ...headers },
    }).then(handleResponse)

const patch = (url, body, headers = []) =>
    fetch(BASE_URL + url, {
        method: "PATCH",
        headers: { "Content-Type": "application/json", ...defaultHeaders, ...headers },
        body: JSON.stringify(body),
    }).then(handleResponse)

export const ApiClient = {
    get,
    post,
    put,
    delete: _delete,
    patch,
}

// helper functions

async function handleResponse(response) {
    const text = await response.text()
    const data = text && JSON.parse(text)

    const headers = {}
    response.headers.forEach((val, key) => (headers[key] = val))

    if (!response.ok) {
        const error =
            data && data.diagnostics
                ? { code: data.diagnostics.code, message: data.diagnostics.message }
                : { code: "error", message: response.statusText }

        return Promise.reject(error)
    }

    return {
        data,
        headers,
    }
}
