import { format as fnsFormat, parseISO } from "date-fns"

/**
 * @param {string} time The time as an ISO 8601 string
 * @param {string} format
 */
export const formatDateTime = (time, format = "yyyy-MM-dd HH:mm") => {
    if (!time) {
        return "..."
    }
    const date = parseISO(time)
    return fnsFormat(date, format)
}
