import { Col, Form, Pagination, Row } from "react-bootstrap"

export const TableControls = ({
    totalCount,
    pageSizes,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
}) => {
    const pageCount = Math.ceil(totalCount / pageSize)

    return (
        <Row>
            <Col>
                <Form.Group style={{ width: "100px" }}>
                    <Form.Select
                        id="table-limit"
                        onChange={(e) => {
                            setPageSize(e.target.value)
                            setCurrentPage(0)
                        }}
                        value={pageSize}
                    >
                        {pageSizes.map((entry) => (
                            <option key={entry}>{entry}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col>
                <Pagination className="justify-content-end mb-0">
                    <Pagination.Prev
                        disabled={currentPage <= 0}
                        onClick={() => setCurrentPage(currentPage - 1)}
                    />

                    {pageCount < 8 ? (
                        Array.from(Array(pageCount), (_, page) => (
                            <Item
                                key={page}
                                current={currentPage}
                                page={page}
                                set={setCurrentPage}
                            />
                        ))
                    ) : (
                        <BigPagination
                            count={pageCount}
                            current={currentPage}
                            set={setCurrentPage}
                        />
                    )}

                    <Pagination.Next
                        disabled={currentPage + 1 >= pageCount}
                        onClick={() => setCurrentPage(currentPage + 1)}
                    />
                </Pagination>
            </Col>
        </Row>
    )
}

const BigPagination = ({ count, current, set }) => {
    const onFirstThreePages = current <= 3
    const onLastThreePages = current + 4 >= count

    let offset = 0
    if (!onFirstThreePages && !onLastThreePages) {
        offset = current - 2
    } else if (onLastThreePages) {
        offset = count - 5
    }

    return (
        <>
            {onFirstThreePages || (
                <>
                    <Item current={current} page={0} set={set} />
                    <Pagination.Ellipsis disabled />
                </>
            )}

            {Array.from(Array(5), (_, page) => (
                <Item key={page} current={current} page={page + offset} set={set} />
            ))}

            {onLastThreePages || (
                <>
                    <Pagination.Ellipsis disabled />
                    <Item current={current} page={count - 1} set={set} />
                </>
            )}
        </>
    )
}

const Item = ({ current, page, set }) => (
    <Pagination.Item active={current === page} onClick={() => set(page)}>
        {page + 1} <span className="sr-only">{page + 1}</span>
    </Pagination.Item>
)
