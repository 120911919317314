import classNames from "classnames"
import { milliseconds } from "date-fns"
import { useHistory } from "../HistoryContext"
import { Line } from "react-chartjs-2"
import { settingFromPeriod } from "./ChartList"
import { VentilationLevels } from "../../enums/VentilationLevels"
import { filterToLabel, lineColors, nowLineColor } from "./LineChart"
import { Spinner } from "react-bootstrap"
import { useMemo, useCallback, useRef, useEffect } from "react"

const MappedLevels = VentilationLevels.map((x) => x.toUpperCase())

export const BooleanChart = ({ data, loading, chartIdentifier, chartId, showInts }) => {
    const { start, end, afterZoom, addGraphData } = useHistory()
    const setting = settingFromPeriod(start, end)

    const chartRef = useRef(null)

    const chartData = useMemo(
        () => ({
            datasets: Object.entries(data).map(([key, val], i) => {
                const base = {
                    key: key,
                    label: filterToLabel(key, val),
                    backgroundColor: lineColors[i],
                    borderColor: lineColors[i],
                    borderWidth: 1,
                    cubicInterpolationMode: "monotone",
                    stepped: "after",
                    hidden: false,
                }

                if (key === "ventilation_level") {
                    base.data = val.data.map((v) => ({
                        x: new Date(v.timestamp),
                        y: MappedLevels.indexOf(v.value) + 1,
                    }))
                    base.yAxisID = "yInts"
                } else {
                    base.data = val.data.map((v) => ({
                        x: new Date(v.timestamp),
                        y: (v.value * 1) % 2 === 0 ? i * 2 : i * 2 + 1,
                    }))
                    base.yAxisID = "y"
                }

                return base
            }),
        }),
        [data]
    )

    const setChartData = useCallback(
        (chart) => {
            if (!chart) {
                return
            }

            const chartId = chart.canvas.id
            const data = chart._metasets
                .filter((set) => set.visible)
                .flatMap((item) =>
                    item._dataset.data.map((datapoint) => ({
                        timestamp: datapoint.x.toISOString(),
                        datapointType: item.label,
                        value: datapoint.y,
                    }))
                )

            addGraphData(chartIdentifier, chartId, data)
        },
        [addGraphData, chartIdentifier]
    )

    useEffect(() => {
        setChartData(chartRef.current)
    }, [setChartData, loading, data])

    const options = useMemo(() => {
        const opts = baseOptions()
        opts.scales.x.min = start.valueOf()
        opts.scales.x.max = end.valueOf()
        opts.scales.x.time.unit = setting.unit
        opts.scales.x.time.displayFormats = {
            minute: setting.format,
            hour: setting.format,
            day: setting.format,
            week: setting.format,
            month: setting.format,
            year: setting.format,
        }
        opts.scales.x.ticks.maxTicksLimit = setting.maxTicks
        opts.scales.y.suggestedMax = data.length * 2 - 1
        opts.plugins.zoom.zoom.onZoomComplete = ({ chart }) =>
            afterZoom(chart.scales.x.min, chart.scales.x.max)
        opts.plugins.annotation.annotations[0].value = new Date()
        opts.plugins.legend.onClick = (e, legendItem, legend) => {
            const meta = legend.chart.getDatasetMeta(legendItem.datasetIndex)
            // Update label
            meta.hidden = meta.visible
            legend.chart.update()

            setChartData(legend.chart)
        }
        if (!showInts) {
            delete opts.scales.yInts
        }
        return opts
    }, [afterZoom, setChartData, start, end, setting, data, showInts])

    return (
        <div className={classNames("history-graph", { loading: loading })}>
            <Line options={options} id={chartId} ref={chartRef} data={chartData} />
            <div>
                <Spinner variant="primary" />
            </div>
        </div>
    )
}

const baseOptions = () => ({
    responsive: true,
    elements: {
        point: {
            radius: 1,
        },
    },
    scales: {
        x: {
            type: "time",
            time: {
                tooltipFormat: "dd-MM-yyyy HH:mm:ss",
            },
            ticks: {
                align: "start",
            },
        },
        y: {
            suggestedMin: 0,
            stack: "customVertical",
            // Uncomment if it is necessary to shrink this axis relative to the other
            // stackWeight: 0.25,
            offset: true,
            ticks: {
                precision: 0,
                callback: (value) => (value % 2 !== 0 ? "ON" : "OFF"),
                font: {
                    size: 10,
                },
            },
        },
        yInts: {
            display: true,
            suggestedMin: 0,
            suggestedMax: 4,
            stack: "customVertical",
            ticks: {
                precision: 0,
            },
        },
    },
    plugins: {
        tooltip: {
            callbacks: {
                label: (value) =>
                    value.dataset.key === "ventilation_level"
                        ? value.formattedValue
                        : value.raw.y % 2 !== 0
                        ? "ON"
                        : "OFF",
            },
        },
        legend: {
            position: "bottom",
            labels: {
                usePointStyle: true,
            },
        },
        zoom: {
            limits: {
                x: {
                    min: "original",
                    max: "original",
                    minRange: milliseconds({ minutes: 3 }),
                },
            },
            zoom: {
                drag: {
                    enabled: true,
                },
                mode: "x",
            },
        },
        annotation: {
            annotations: [
                {
                    type: "line",
                    id: "vline1",
                    mode: "vertical",
                    scaleID: "x",
                    borderColor: nowLineColor,
                    borderWidth: 2,
                    label: {
                        display: true,
                        position: "start",
                        content: "Now",
                        backgroundColor: nowLineColor,
                    },
                },
            ],
        },
    },
})
