import { useState } from "react"
import { Button, ButtonGroup } from "react-bootstrap"

export const ConfirmButton = ({ onConfirm, generalClassName, children, ...props }) => {
    const [active, setActive] = useState(false)

    return active ? (
        <ButtonGroup className={generalClassName}>
            <Button variant="light" onClick={() => setActive(false)}>
                Cancel
            </Button>
            <Button
                variant="danger"
                onClick={() => {
                    onConfirm()
                    setActive(false)
                }}
            >
                Confirm
            </Button>
        </ButtonGroup>
    ) : (
        <Button onClick={() => setActive(true)} className={generalClassName} {...props}>
            {children}
        </Button>
    )
}
