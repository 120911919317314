import React from "react"
import { DetailLink } from "../../components/Table/DetailLink"
import { GenericOverview } from "../../components/Table/GenericOverview"
import { useNavigate } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { ConnectedIcon } from "../../components/ConnectedIcon"

export const DeviceOverviewRemoteLogging = () => {
    const endpoint = "/v2/management/devices/remote-logging"

    const jsonDataField = (setData, data) => setData(data.devices)
    const navigate = useNavigate()

    const error = {
        title: "Failed to get devices",
        body:
            "Something went wrong while getting the devices, " +
            "see the console for more details.",
    }

    const emptyMsg = "No devices found"

    const table = {
        header: [
            <th>SN</th>,
            <th>Type</th>,
            <th>Firmware</th>,
            <th>Connected</th>,
            <th>Actions</th>,
        ],
        body: (value) => (
            <tr
                key={value.device_id}
                onClick={() => {
                    navigate(`/device/${value.device_id}`)
                }}
            >
                <td>{value.sn}</td>
                <td>{value.type}</td>
                <td>{value.current_version}</td>
                <td>
                    <ConnectedIcon connected={value.connected} />
                </td>
                <td>
                    <DetailLink to={`/device/${value.device_id}`} />
                </td>
            </tr>
        ),
    }

    return (
        <Row className="content-section">
            <Col>
                <h2 className="mb-3">Remote logging enabled devices</h2>
                <GenericOverview
                    apiEndpoint={endpoint}
                    dataField={jsonDataField}
                    filter={false}
                    error={error}
                    table={table}
                    emptyMsg={emptyMsg}
                    disableSearch={true}
                />
            </Col>
        </Row>
    )
}
