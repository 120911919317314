import { useAuth0 } from "@auth0/auth0-react"
import React, { useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { ApiClient } from "../../ApiClient"
import { MessageType, useMessageReport } from "../../components/MessageReporter"
import { useNavigate } from "react-router-dom"
import { FirmwareSelector } from "../../components/Firmware/FirmwareSelector"

const devMode = process.env.REACT_APP_DEV_MODE === "true"

export const AddDeviceGroup = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()
    const navigate = useNavigate()

    const [groupName, setGroupName] = useState("")
    const [child, setChild] = useState({
        value: {
            firmware_id: "",
            device_type: "",
        },
    })
    const deviceType = child.value.device_type
    const firmwareId = child.value.firmware_id

    const formValid = groupName !== "" && deviceType !== "" && firmwareId !== ""

    const create = () => {
        const body = {
            type: "GROUP",
            name: groupName,
            device_type: deviceType,
            children: [firmwareId],
        }

        getAccessTokenSilently()
            .then((token) =>
                ApiClient.post("/v2/management/firmwares", body, {
                    Authorization: "Bearer " + token,
                })
            )
            .then(({ headers }) => navigate("/devicegroup/" + headers.location))
            .catch((e) => {
                addMessage(
                    "Failed to add device group",
                    "Something went wrong while adding the device group, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })
    }

    return (
        <Row className="content-section">
            <Col>
                <h2>Add device group</h2>

                <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                        Special values:
                        <ul>
                            <li>
                                <span
                                    className="link-success"
                                    role="button"
                                    onClick={() => setGroupName("Stable")}
                                >
                                    Stable
                                </span>
                                , newly connected devices will be put in this group.
                            </li>
                            {devMode && (
                                <li>
                                    <span
                                        className="link-success"
                                        role="button"
                                        onClick={() => setGroupName("NoUpdate")}
                                    >
                                        NoUpdate
                                    </span>
                                    , devices that (directly) have this group will never be updated.
                                </li>
                            )}
                        </ul>
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Direct child device group or firmware version</Form.Label>
                    <FirmwareSelector value={child} setValue={setChild} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Device type</Form.Label>
                    <Form.Control type="text" readOnly disabled value={deviceType || "..."} />
                </Form.Group>

                <Button disabled={!formValid} onClick={() => create()}>
                    Create
                </Button>
            </Col>
        </Row>
    )
}
