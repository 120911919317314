import classNames from "classnames"
import { milliseconds } from "date-fns"
import { useHistory } from "../HistoryContext"
import { Line } from "react-chartjs-2"
import { settingFromPeriod } from "./ChartList"
import { Spinner } from "react-bootstrap"
import { useCallback, useEffect, useMemo, useRef } from "react"

export const lineColors = {
    0: "#F44336", // Red
    4: "#E91E63", // Pink
    8: "#9C27B0", // Purple
    12: "#673AB7", // Deep Purple
    16: "#3F51B5", // Indigo

    1: "#2196F3", // Blue
    5: "#03A9F4", // Light Blue
    9: "#00BCD4", // Cyan
    13: "#009688", // Teal

    2: "#4CAF50", // Green
    6: "#8BC34A", // Light Green
    10: "#CDDC39", // Lime
    14: "#FFEB3B", // Yellow

    3: "#FFC107", // Amber
    7: "#FF9800", // Orange
    11: "#FF5722", // Deep Orange
    15: "#795548", // Brown
}
export const nowLineColor = "#A5D6A7"

export const filterToLabel = (key, value) =>
    key[0].toUpperCase() +
    key.slice(1).replaceAll("_", " ") +
    (value.unit === "none" ? "" : " (" + value.unit + ")")

export const LineChart = ({ values, data, loading, chartIdentifier, chartId }) => {
    const { start, end, afterZoom, addGraphData } = useHistory()
    const setting = settingFromPeriod(start, end)
    const chartRef = useRef(null)

    const chartData = useMemo(
        () => ({
            datasets: Object.entries(data).map(([key, val], i) => ({
                key: key,
                label: filterToLabel(key, val),
                data: val.data.map((v) => ({ x: new Date(v.timestamp), y: v.value })),
                backgroundColor: lineColors[i],
                borderColor: lineColors[i],
                borderWidth: 1,
                cubicInterpolationMode: "monotone",
                stepped:
                    values.filter((v) => v.key === key).pop().type === "event" ? "after" : false,
                hidden: values.filter((v) => v.key === key).pop().hide,
            })),
        }),
        [values, data]
    )

    const setChartData = useCallback(
        (chart) => {
            if (!chart) {
                return
            }

            const chartId = chart.canvas.id
            const data = chart._metasets
                .filter((set) => set.visible)
                .flatMap((item) =>
                    item._dataset.data.map((datapoint) => ({
                        timestamp: datapoint.x.toISOString(),
                        datapointType: item.label,
                        value: datapoint.y,
                    }))
                )

            addGraphData(chartIdentifier, chartId, data)
        },
        [addGraphData, chartIdentifier]
    )

    useEffect(() => {
        setChartData(chartRef.current)
    }, [setChartData, loading, data])

    const options = useMemo(() => {
        const opts = baseOptions()
        opts.scales.x.min = start.valueOf()
        opts.scales.x.max = end.valueOf()
        opts.scales.x.time.unit = setting.unit
        opts.scales.x.time.displayFormats = {
            minute: setting.format,
            hour: setting.format,
            day: setting.format,
            week: setting.format,
            month: setting.format,
            year: setting.format,
        }
        opts.plugins.legend.onClick = (e, legendItem, legend) => {
            const meta = legend.chart.getDatasetMeta(legendItem.datasetIndex)
            // Update label
            meta.hidden = meta.visible
            legend.chart.update()

            setChartData(legend.chart)
        }

        opts.scales.x.ticks.maxTicksLimit = setting.maxTicks
        opts.plugins.zoom.zoom.onZoomComplete = ({ chart }) =>
            afterZoom(chart.scales.x.min, chart.scales.x.max)
        opts.plugins.annotation.annotations[0].value = new Date()

        return opts
    }, [afterZoom, setChartData, start, end, setting])

    return (
        <div className={classNames("history-graph", { loading: loading })}>
            <Line id={chartId} options={options} data={chartData} ref={chartRef} />
            <div>
                <Spinner variant="primary" />
            </div>
        </div>
    )
}

const baseOptions = () => ({
    responsive: true,
    elements: {
        point: {
            radius: 1,
        },
    },
    scales: {
        x: {
            type: "time",
            time: {
                tooltipFormat: "dd-MM-yyyy HH:mm:ss",
            },
            ticks: {
                align: "start",
            },
        },
        y: {
            suggestedMin: 0,
        },
    },
    plugins: {
        legend: {
            position: "bottom",
            labels: {
                usePointStyle: true,
            },
        },
        zoom: {
            limits: {
                x: {
                    min: "original",
                    max: "original",
                    minRange: milliseconds({ minutes: 3 }),
                },
            },
            zoom: {
                drag: {
                    enabled: true,
                },
                mode: "x",
            },
        },
        annotation: {
            annotations: [
                {
                    type: "line",
                    id: "vline1",
                    mode: "vertical",
                    scaleID: "x",
                    borderColor: nowLineColor,
                    borderWidth: 2,
                    label: {
                        display: true,
                        position: "start",
                        content: "Now",
                        backgroundColor: nowLineColor,
                    },
                },
            ],
        },
    },
})
