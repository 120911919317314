import { useCallback, useEffect, useState } from "react"
import { ApiClient } from "../../ApiClient"
import { useAuth0 } from "@auth0/auth0-react"
import { useParams } from "react-router-dom"
import { MessageType, useMessageReport } from "../../components/MessageReporter"
import { RemoteLogging } from "../../components/Device/RemoteLogging"
import { DeviceTree } from "../../components/Device/DeviceTree"
import { FirmwareChain } from "../../components/Firmware/FirmwareChain"
import { DeviceInfo } from "../../components/Device/DeviceInfo"
import { DeviceInfoAdmin } from "../../components/Device/DeviceInfoAdmin"
import { DeviceLatestValues } from "../../components/Device/DeviceLatestValues"
import { Col, Row } from "react-bootstrap"
import { RoleProtect } from "../../components/Authorization/RoleProtect"
import { Role } from "../../enums/Role"
import { HistoryContextProvider } from "../../components/HistoryContext"
import { DeviceHistoryControls } from "../../components/Device/DeviceHistoryControls"
import { HistoryGraph } from "../../components/Device/HistoryGraph"

export const DeviceDetail = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()

    const { id } = useParams()
    const [device, setDevice] = useState()
    const [gaveConsent, setGaveConsent] = useState(false)
    const loadDevice = useCallback(() => {
        getAccessTokenSilently()
            .then((token) =>
                ApiClient.get("/v2/management/devices/" + id, {
                    Authorization: "Bearer " + token,
                })
            )
            .then(({ data }) => {
                setDevice(data)
            })
            .catch((e) => {
                addMessage(
                    "Failed to get device",
                    "Something went wrong while getting the device, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })
    }, [getAccessTokenSilently, addMessage, id])

    const reload = () => loadDevice()
    useEffect(() => loadDevice(), [loadDevice])

    useEffect(() => {
        getAccessTokenSilently()
            .then((token) =>
                ApiClient.get(`/v2/consumer/devices/${id}/config?filter=sharing_policy`, {
                    Authorization: "Bearer " + token,
                })
            )
            .then(({ data }) => {
                setGaveConsent(data?.sharing_policy?.shared === true)
            })
            .catch((e) => {
                addMessage(
                    "Failed to get user consent",
                    "Something went wrong while getting the users consent information, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })
    }, [getAccessTokenSilently, addMessage, id])

    return (
        <>
            <Row className="content-section">
                <Col>
                    <h2>Information</h2>
                    {device && <DeviceInfo device={device} />}
                </Col>
                {device && device.connected && gaveConsent && (
                    <Col md={6}>
                        <h2>Status</h2>
                        <DeviceLatestValues id={id} />
                    </Col>
                )}
            </Row>
            <Row className="content-section">
                <Col>
                    <h2>History</h2>
                    <HistoryContextProvider>
                        <DeviceHistoryControls />
                        {device && (
                            <HistoryGraph deviceSn={device.sn} deviceId={device.device_id} />
                        )}
                    </HistoryContextProvider>
                </Col>
            </Row>

            <RoleProtect allowedRoles={[Role.Admin]}>
                <Row className="content-section">
                    <Col xs={12}>
                        <h2 className="heading-small mb-4">Administration</h2>
                    </Col>

                    <Col lg={6}>
                        <h4 className="mb-4">Information</h4>
                        {device && <DeviceInfoAdmin device={device} reloadDevice={reload} />}
                        <hr className="d-lg-none" />
                    </Col>
                    <Col lg={6}>
                        <h4>Device groups & firmware version</h4>
                        {device && (
                            <FirmwareChain
                                firmware={device.set_firmware}
                                doneLoading={device.device_id !== undefined}
                            />
                        )}
                        <hr />
                        <h4>Attached devices</h4>
                        {device && <DeviceTree device={device} />}
                    </Col>

                    <Col xs={12}>
                        <hr />
                    </Col>

                    <Col md={6}>
                        <h5>Remote logging</h5>
                        {device && device.device_id && (
                            <RemoteLogging deviceId={device.device_id} />
                        )}
                    </Col>
                </Row>
            </RoleProtect>
        </>
    )
}
