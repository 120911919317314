import React from "react"
import ReactDOM from "react-dom/client"
import { App } from "./App"
import { GenericErrorBoundary } from "./components/ErrorBoundaries/GenericErrorBoundary"
import { GlobalErrorPage } from "./components/ErrorBoundaries/ErrorPages"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
    <React.StrictMode>
        <GenericErrorBoundary fallback={GlobalErrorPage}>
            <App />
        </GenericErrorBoundary>
    </React.StrictMode>
)
