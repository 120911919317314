import { useEffect, useState } from "react"
import announcements from "../message.json"
import { Alert } from "react-bootstrap"

const activeAnnouncements = announcements.map((a) => a.identifier)

export const Announcement = () => {
    const [shownAnnouncements, setShownAnnouncements] = useState([])

    useEffect(() => {
        const storedAnnouncements = localStorage.getItem("shownAnnouncements")?.split(",") || []
        setShownAnnouncements(storedAnnouncements.filter((a) => activeAnnouncements.includes(a)))
    }, [])

    const stopShowing = (id) => {
        const newAnnouncements = [...shownAnnouncements, id]
        localStorage.setItem("shownAnnouncements", newAnnouncements)
        setShownAnnouncements(newAnnouncements)
    }

    return announcements.map(
        (announcement) =>
            shownAnnouncements.includes(announcement.identifier) || (
                <Alert
                    key={announcement.identifier}
                    className="row"
                    variant={announcement.variant}
                    onClose={() => stopShowing(announcement.identifier)}
                    dismissible
                >
                    <Alert.Heading>{announcement.title}</Alert.Heading>
                    <div dangerouslySetInnerHTML={{ __html: announcement.body }}></div>
                </Alert>
            )
    )
}
