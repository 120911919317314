import { Line } from "react-chartjs-2"
import "chartjs-adapter-date-fns"
import { useAuth0 } from "@auth0/auth0-react"
import { MessageType, useMessageReport } from "../MessageReporter"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ApiClient } from "../../ApiClient"
import { milliseconds } from "date-fns"
import { Spinner } from "react-bootstrap"
import classNames from "classnames"
import { merge } from "lodash"
import { VentilationLevels } from "../../enums/VentilationLevels"
import { useHistory } from "../HistoryContext"
import { filterToLabel, lineColors, nowLineColor } from "../Charts/LineChart"
import { settingFromPeriod } from "../Charts/ChartList"

const defaultDisplay = ["room_setpoint", "ambient_temperature", "flame_state"]
const continuousLines = ["ambient_eco2", "ambient_humidity", "ambient_temperature"]
const eventLines = ["flame_state", "room_setpoint", "ventilation_level", "connected"]

const globalFilters = continuousLines.concat(eventLines)

export const HistoryGraph = ({ deviceId, title, deviceSn }) => {
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()
    const { addGraphData, start, end, afterZoom } = useHistory()

    const [loading, setLoading] = useState(false)

    const [data, setData] = useState({ labels: [], datasets: [] })
    const setting = useMemo(() => settingFromPeriod(start, end), [start, end])

    // Needed because the Date objects in start and end change often,
    // without their content actually changing.
    const startStr = start.toISOString()
    const endStr = end.toISOString()

    useEffect(() => {
        setLoading(true)
        getAccessTokenSilently()
            .then(async (token) => {
                const urls = [
                    `/v2/consumer/history` +
                        `/${deviceId}` +
                        `/${startStr}` +
                        `/${endStr}` +
                        `/original` +
                        `?filter=${eventLines.join(",")}`,
                    `/v2/consumer/history` +
                        `/${deviceId}` +
                        `/${startStr}` +
                        `/${endStr}` +
                        `/${setting.resolution}` +
                        `?filter=${continuousLines.join(",")}`,
                ]

                const results = await Promise.all(
                    urls.map((url) =>
                        ApiClient.get(url, {
                            Authorization: "Bearer " + token,
                        })
                    )
                )
                const combinedResult = {}
                merge(combinedResult, results[0], results[1])

                return combinedResult
            })
            .then(({ data }) => setData(mapHistory(data)))
            .catch((err) =>
                addMessage("Device history", "Could not get device history", MessageType.error, err)
            )
            .finally(() => setLoading(false))
    }, [getAccessTokenSilently, addMessage, deviceId, startStr, endStr, setting])

    return (
        data.datasets.length > 0 && (
            <div className={classNames("history-graph", { loading: loading })}>
                {title && <h3>{title}</h3>}
                <GraphHelper
                    data={data}
                    afterZoom={afterZoom}
                    start={start}
                    end={end}
                    setting={setting}
                    addGraphData={addGraphData}
                    deviceSn={deviceSn}
                />
                <div>
                    <Spinner variant="primary" />
                </div>
            </div>
        )
    )
}

const GraphHelper = ({ data, afterZoom, start, end, setting, addGraphData, deviceSn }) => {
    const chartRef = useRef(null)

    const setChartData = useCallback(
        (chart) => {
            if (!chart) {
                return
            }

            const chartId = chart.canvas.id
            const data = chart._metasets
                .filter((set) => set.visible)
                .flatMap((item) =>
                    item._dataset.data.map((datapoint) => ({
                        timestamp: datapoint.x.toISOString(),
                        datapointType: item.label,
                        value: datapoint.y,
                    }))
                )

            addGraphData(deviceSn, chartId, data)
        },
        [addGraphData, deviceSn]
    )

    useEffect(() => {
        setChartData(chartRef.current)
    }, [setChartData, data])

    const options = {
        responsive: true,
        elements: {
            point: {
                radius: 1,
            },
        },
        scales: {
            x: {
                type: "time",
                min: start.valueOf(),
                max: end.valueOf(),
                time: {
                    unit: setting.unit,
                    tooltipFormat: "dd-MM-yyyy HH:mm:ss",
                    displayFormats: {
                        minute: setting.format,
                        hour: setting.format,
                        day: setting.format,
                        week: setting.format,
                        month: setting.format,
                        year: setting.format,
                    },
                },
                ticks: {
                    maxTicksLimit: setting.maxTicks,
                    align: "start",
                },
            },
            y: {
                suggestedMin: 0,
            },
        },
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    usePointStyle: true,
                },
                onClick: (e, legendItem, legend) => {
                    const meta = legend.chart.getDatasetMeta(legendItem.datasetIndex)
                    // Update label
                    meta.hidden = meta.visible
                    legend.chart.update()

                    setChartData()
                },
            },
            zoom: {
                limits: {
                    x: {
                        min: "original",
                        max: "original",
                        minRange: milliseconds({ minutes: 3 }),
                    },
                },
                zoom: {
                    drag: {
                        enabled: true,
                    },
                    mode: "x",
                    onZoomComplete: ({ chart }) =>
                        afterZoom(chart.scales.x.min, chart.scales.x.max),
                },
            },
            annotation: {
                annotations: [
                    {
                        type: "line",
                        id: "vline1",
                        mode: "vertical",
                        scaleID: "x",
                        value: new Date(),
                        borderColor: nowLineColor,
                        borderWidth: 2,
                        label: {
                            display: true,
                            position: "start",
                            content: "Now",
                            backgroundColor: nowLineColor,
                        },
                    },
                ],
            },
        },
    }

    return <Line ref={chartRef} id="history" options={options} data={data} />
}

const MappedLevels = VentilationLevels.map((x) => x.toUpperCase())

const mapHistory = (res) => {
    let globalSets = []

    Object.entries(res).forEach((entry) => {
        if (globalFilters.includes(entry[0]) && entry[1].data.length > 0) {
            let values = []

            if (entry[0] === "ventilation_level") {
                values = entry[1].data.map((x) => {
                    const value = x.value
                    const level = MappedLevels.indexOf(value) + 1

                    return {
                        x: new Date(x.timestamp),
                        y: level,
                    }
                })
            } else {
                values = entry[1].data.map((x) => ({
                    x: new Date(x.timestamp),
                    y: x.value,
                }))
            }

            const stepped = continuousLines.indexOf(entry[0]) < 0 ? "after" : false
            globalSets.push({
                label: filterToLabel(entry[0], entry[1]),
                data: values,
                backgroundColor:
                    lineColors[globalFilters.indexOf(entry[0]) % Object.keys(lineColors).length],
                borderColor:
                    lineColors[globalFilters.indexOf(entry[0]) % Object.keys(lineColors).length],
                borderWidth: 1,
                hidden: !defaultDisplay.includes(entry[0]),
                cubicInterpolationMode: "monotone",
                stepped: stepped,
            })
        }
    })

    return {
        labels: [],
        datasets: globalSets,
    }
}
