import { Button, Form, FormGroup, FormSelect, Col, Row } from "react-bootstrap"
import { MessageType, useMessageReport } from "../../components/MessageReporter"
import { useAuth0 } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { Role, Roles } from "../../enums/Role"
import { ApiClient } from "../../ApiClient"

const FilteredRoles = Roles.filter((role) => role !== Role.Consumer)

export const UserAdd = () => {
    const { addMessage } = useMessageReport()
    const { getAccessTokenSilently } = useAuth0()
    const navigate = useNavigate()

    const [role, setRole] = useState(Role.Support)
    const [email, setEmail] = useState("")

    const createUser = () => {
        getAccessTokenSilently()
            .then((token) =>
                ApiClient.post(
                    "/v2/consumer/users/role",
                    {
                        email: email,
                        role: role,
                    },
                    {
                        Authorization: "Bearer " + token,
                    }
                )
            )
            .then(({ headers }) => navigate("/user/" + headers.location))
            .catch((err) => {
                addMessage(
                    "Failed to create user",
                    "Something went wrong while creating the user, " +
                        "see the console for more details.",
                    MessageType.error,
                    err
                )
            })
    }

    return (
        <Row className="content-section">
            <Col>
                <h2>User creation</h2>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />
                </Form.Group>
                <FormGroup>
                    <Form.Label>Role</Form.Label>
                    <FormSelect
                        value={role}
                        onChange={(e) => {
                            setRole(e.target.value)
                        }}
                    >
                        {FilteredRoles.map((role) => (
                            <option key={role} value={role}>
                                {role.toLowerCase()}
                            </option>
                        ))}
                    </FormSelect>
                </FormGroup>
                <Button className="mt-4" onClick={createUser}>
                    Create
                </Button>
            </Col>
        </Row>
    )
}
