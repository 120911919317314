import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

export const NotFound = () => (
    <Row className="content-section">
        <Col>
            <h3>This page does not exist</h3>
            <Link to="/">Go back to homepage</Link>
        </Col>
    </Row>
)
