const mapStatusToFa = (status) => {
    switch (status) {
        case "service_required":
        case "error":
            return "fa-triangle-exclamation text-danger"
        case "warning":
            return "fa-triangle-exclamation text-warning"
        default:
            return "fa-check"
    }
}

export const SystemStatusIcon = ({ status, ...props }) => (
    <span {...props}>
        <i className={"fa-solid " + mapStatusToFa(status)}></i>
    </span>
)
