import React, { cloneElement, useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { MessageType, useMessageReport } from "../MessageReporter"
import { useDebounce } from "use-debounce"
import { ApiClient } from "../../ApiClient"
import { Alert, Form, Spinner, Table } from "react-bootstrap"
import { SortableTr } from "../SortableColumns"
import { TableControls } from "./TableControls"
import classNames from "classnames"

const pageSizes = [20, 50, 100]

export const GenericOverview = ({
    apiEndpoint,
    dataField,
    filter,
    error,
    table,
    emptyMsg,
    extraParam,
    disableSearch,
}) => {
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()

    const [loading, setLoading] = useState(true)
    const [tableError, setTableError] = useState(false)

    const [pageSize, setPageSize] = useState(pageSizes[1])
    const [currentPage, setCurrentPage] = useState(0)

    const [data, setData] = useState([])
    const [dataCount, setDataCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)

    const [sort, setSort] = useState("")
    const [direction, setDirection] = useState("asc")
    const [search, setSearch] = useState("")
    const [searchText] = useDebounce(search, 500)

    useEffect(() => setCurrentPage(0), [searchText])

    useEffect(() => {
        setLoading(true)

        let filters = structuredClone(filter.initial)
        if (searchText.length > 2) {
            filters = filter.onSearch(filters, searchText)
        }

        const dir = direction === "desc" ? "-" : ""

        getAccessTokenSilently()
            .then((token) =>
                ApiClient.get(
                    apiEndpoint +
                        `?size=${pageSize}` +
                        `&page=${currentPage}` +
                        `&sort=${dir}${sort}` +
                        `&filters=${JSON.stringify(filters)}` +
                        (extraParam ? extraParam : ""),

                    {
                        Authorization: `Bearer ${token}`,
                    }
                )
            )
            .then(({ data }) => {
                if (data === undefined || data.total_count === undefined) {
                    return Promise.reject(data)
                }

                const itemKey = Object.keys(data).find(
                    (key) => key !== "total_count" && key !== "diagnostics"
                )
                const length = data[itemKey].length

                dataField(setData, data)
                setDataCount(length)
                setTotalCount(data.total_count)

                setTableError(false)
            })
            .catch((e) => {
                setData([])
                setTotalCount(0)
                setTableError(true)
                addMessage(error.title, error.body, MessageType.error, e)
            })
            .finally(() => setLoading(false))
    }, [
        getAccessTokenSilently,
        addMessage,
        pageSize,
        sort,
        direction,
        searchText,
        currentPage,
        apiEndpoint,
        dataField,
        error,
        filter,
        extraParam,
    ])

    return (
        <>
            {!disableSearch && (
                <Form.Control
                    id="search-bar-input"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search"
                    type="text"
                />
            )}
            <p className="mt-2">
                <ResultCount
                    currentPage={currentPage}
                    pageSize={pageSize}
                    dataCount={dataCount}
                    totalCount={totalCount}
                />
            </p>
            <GenericOverviewTable
                data={data}
                loading={loading}
                tableError={tableError}
                errorText={error}
                table={table}
                emptyMsg={emptyMsg}
                sort={sort}
                setSort={setSort}
                direction={direction}
                setDirection={setDirection}
            />
            <TableControls
                totalCount={totalCount}
                pageSizes={pageSizes}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </>
    )
}

export const ResultCount = ({ currentPage, pageSize, totalCount, dataCount }) => {
    let start = 0
    let end = 0

    if (totalCount > 0) {
        const offset = currentPage * pageSize
        start = offset + 1
        end = offset + dataCount
    }

    return `(${start} - ${end} of ${totalCount} results)`
}

export const GenericOverviewTable = ({
    loading,
    data,
    tableError,
    errorText,
    table,
    emptyMsg,
    sort,
    setSort,
    direction,
    setDirection,
}) => {
    return (
        <>
            <div
                // This hack triggers some css in _overview-table.scss. This is needed because we
                // can't directly style the div in the react-bootstrap's Table.
                className={classNames({ "one-child": data.length === 1 })}
            >
                <Table responsive striped hover>
                    <thead className="thead-light">
                        <SortableTr
                            sortKey={sort}
                            sortDir={direction}
                            onChange={(sort, direction) => {
                                setDirection(direction)
                                setSort(sort)
                            }}
                        >
                            {table.header.map((elem, index) => cloneElement(elem, { key: index }))}
                        </SortableTr>
                    </thead>
                    <tbody>{loading || data.map((value) => table.body(value))}</tbody>
                </Table>
            </div>
            {loading && <Spinner className="mb-3" variant="primary" />}
            {tableError && (
                <Alert variant="danger">
                    <strong>{errorText.title}</strong>
                    <br />
                    {errorText.body}
                </Alert>
            )}
            {!loading && !tableError && data.length === 0 && <div className="mb-3">{emptyMsg}</div>}
        </>
    )
}
