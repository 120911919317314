import { Button, Col, Form, FormGroup, FormSelect, Row } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { MessageType, useMessageReport } from "../../components/MessageReporter"
import { ApiClient } from "../../ApiClient"
import { InfoList } from "../../components/InfoList"
import { formatDateTime } from "../../util/formatDataTime"
import { Roles } from "../../enums/Role"

export const UserDetail = () => {
    const { id } = useParams()
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()

    const [user, setUser] = useState()
    const [role, setRole] = useState()
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState(false)

    useEffect(() => {
        getAccessTokenSilently()
            .then((token) =>
                ApiClient.get("/v2/consumer/users/" + id, {
                    Authorization: "Bearer " + token,
                })
            )
            .then(({ data: user }) => {
                setUser(user)
                setRole(user.role)
            })
            .catch((e) => {
                addMessage(
                    "Failed to get user",
                    "Something went wrong while getting the user, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })
    }, [getAccessTokenSilently, addMessage, id])

    const updateUser = () => {
        setUpdateButtonDisabled(true)
        getAccessTokenSilently()
            .then((token) =>
                ApiClient.patch(
                    "/v2/consumer/users/" + user.id,
                    {
                        role: role,
                    },
                    {
                        Authorization: "Bearer " + token,
                    }
                ).then(() => {
                    addMessage("User updated", "Successfully updated user", MessageType.success)
                })
            )
            .catch((e) => {
                addMessage(
                    "Failed to update user",
                    "Something went wrong while updating the user, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })
            .finally(setUpdateButtonDisabled(false))
    }

    return (
        <Row className="content-section">
            <Col>
                <h2>User information</h2>
                {user && (
                    <>
                        <InfoList>
                            <InfoList.Item name="Id" value={user.id} />
                            <InfoList.Item name="Email" value={user.email} />
                            <InfoList.Item
                                name="Last activity"
                                value={formatDateTime(user.last_auth)}
                            />
                        </InfoList>
                        <FormGroup>
                            <Form.Label>Role</Form.Label>
                            <FormSelect
                                value={role}
                                onChange={(e) => {
                                    setRole(e.target.value)
                                }}
                            >
                                {Roles.map((role) => (
                                    <option key={role} value={role}>
                                        {role.toLowerCase()}
                                    </option>
                                ))}
                            </FormSelect>
                        </FormGroup>
                        <Button
                            className="mt-4"
                            disabled={updateButtonDisabled}
                            onClick={updateUser}
                        >
                            Update
                        </Button>
                    </>
                )}
            </Col>
        </Row>
    )
}
