import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import classNames from "classnames"
import { Dropdown, Container, Offcanvas, Stack, Navbar, Spinner } from "react-bootstrap"
import React, { useState } from "react"
import { Link, NavLink, Outlet } from "react-router-dom"
import { MessageReporter } from "../components/MessageReporter"
import { useRoleContext } from "../components/RoleContext"
import { Role } from "../enums/Role"
import { Announcement } from "../components/Announcement"

const menuItems = [
    {
        path: "/",
        name: "Home",
        icon: "fa-solid fa-home",
        allowedRoles: [Role.Support, Role.Developer, Role.Admin],
    },
    {
        path: "/system",
        name: "Systems",
        icon: "fa-solid fa-network-wired",
        allowedRoles: [Role.Support, Role.Developer, Role.Admin],
    },
    {
        path: "/device",
        name: "Devices",
        icon: "fa-regular fa-hard-drive",
        allowedRoles: [Role.Support, Role.Developer, Role.Admin],
    },
    {
        path: "/devicegroup",
        name: "Device groups",
        icon: "fa-regular fa-rectangle-list",
        allowedRoles: [Role.Admin],
    },
    {
        path: "/firmware",
        name: "Firmware",
        icon: "fa-regular fa-file-code",
        allowedRoles: [Role.Admin],
    },
    {
        path: "/remotelogging",
        name: "Remote logging",
        icon: "fa-solid fa-book",
        allowedRoles: [Role.Admin],
    },
    {
        path: "/user",
        name: "Users",
        icon: "fa-regular fa-user",
        allowedRoles: [Role.Admin],
    },
]

export const Layout = withAuthenticationRequired(() => {
    const { role } = useRoleContext()

    return (
        <>
            <MobileMenu role={role} />
            <SideBar role={role} />
            <div className="partial-background">
                <div className="m-3 m-lg-4">
                    <main className="container-lg">
                        <Announcement />
                        {role === undefined ? (
                            <Spinner />
                        ) : ![Role.Admin, Role.Support, Role.Developer].includes(role) ? (
                            <UnauthorizedMessage />
                        ) : (
                            <Outlet />
                        )}
                    </main>
                    <footer className="m-3 m-lg-4 text-muted"></footer>
                </div>
            </div>
            <MessageReporter />
        </>
    )
})

const MobileMenu = ({ role }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <>
            <Navbar expand="xs" className="d-lg-none bg-white">
                <Container fluid>
                    <BrandImage height="50px" />
                    <Navbar.Toggle onClick={() => setMobileMenuOpen(true)} />
                </Container>
            </Navbar>
            <Offcanvas show={mobileMenuOpen} onHide={() => setMobileMenuOpen(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <BrandImage height="50px" />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Stack as="nav" gap={2} className="p-3">
                        {menuItems
                            .filter((c) => !c.allowedRoles || c.allowedRoles.includes(role))
                            .map((item) => (
                                <NavItem
                                    key={item.path}
                                    item={item}
                                    onClick={() => setMobileMenuOpen(false)}
                                />
                            ))}
                        <hr className="border-secondary" />
                        <ProfileBtn />
                    </Stack>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

const SideBar = ({ role }) => (
    <Stack as="nav" gap={2} className="sidebar p-3 d-none d-lg-flex bg-white shadow-sm h-100">
        <BrandImage />
        <hr className="border-secondary" />
        {menuItems
            .filter((c) => !c.allowedRoles || c.allowedRoles.includes(role))
            .map((item) => (
                <NavItem key={item.path} item={item} />
            ))}
        <div className="flex-grow-1" />
        <ProfileBtn />
    </Stack>
)

const BrandImage = (props) => (
    <Link className="navbar-brand" to="/">
        <img {...props} className="d-block w-75 mx-auto" alt="Ben logo" src="/ben.png" />
    </Link>
)

const NavItem = ({ item, onClick }) => (
    <NavLink
        key={item.path}
        onClick={onClick}
        to={item.path}
        className={({ isActive }) =>
            classNames("btn btn-light text-start", { "text-muted": !isActive })
        }
    >
        <i className={`${item.icon} fa-fw me-2`} />
        {item.name}
    </NavLink>
)

const ProfileBtn = () => {
    const { user, isLoading, isAuthenticated, logout } = useAuth0()
    const doLogout = () => logout({ logoutParams: { returnTo: window.location.origin } })

    return (
        <Dropdown className="profile-btn">
            <Dropdown.Toggle
                variant="light"
                className="text-start text-muted text-truncate w-100 caret-off"
            >
                <i className="fa-regular fa-user me-2" />
                {isLoading || !isAuthenticated ? "Loading..." : user.nickname}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={doLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

const UnauthorizedMessage = () => (
    <div className="content-section">
        You do not have access to this application.
        <br />
        Please contact support if you need access.
    </div>
)
