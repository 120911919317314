import { useAuth0 } from "@auth0/auth0-react"
import { MessageType, useMessageReport } from "../MessageReporter"
import { useNavigate, useParams } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"
import { ApiClient } from "../../ApiClient"
import { Button, ButtonGroup, Form } from "react-bootstrap"
import { FirmwareChain } from "../Firmware/FirmwareChain"
import { FirmwareSelector } from "../Firmware/FirmwareSelector"
import { ConfirmButton } from "../ConfirmButton"

export const DeviceGroupEdit = () => {
    const { getAccessTokenSilently } = useAuth0()
    const navigate = useNavigate()
    const { addMessage } = useMessageReport()
    const { id } = useParams()

    const [editing, setEditing] = useState(false)

    const [deviceGroup, setDeviceGroup] = useState({})
    const [name, setName] = useState("...")
    const [child, setChild] = useState({
        label: "...",
        value: {},
    })

    const [errorMsg, setErrorMsg] = useState("")

    const firmware = deviceGroup?.children ? deviceGroup.children[0] : null

    const loadDeviceGroup = useCallback(() => {
        getAccessTokenSilently()
            .then((token) =>
                ApiClient.get("/v2/management/firmwares/" + id, {
                    Authorization: "Bearer " + token,
                })
            )
            .then(({ data }) => {
                setDeviceGroup(data)
                setName(data.name)

                if (data.children && data.children.length > 0) {
                    const child = data.children[0]

                    setChild({
                        label: child.name || child.version,
                        value: child,
                    })
                }
            })
            .catch((e) => {
                addMessage(
                    "Failed to load device group",
                    "Something went wrong while loading the device group, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })
    }, [getAccessTokenSilently, addMessage, id])
    useEffect(() => loadDeviceGroup(), [loadDeviceGroup])

    const update = () =>
        getAccessTokenSilently()
            .then((token) =>
                ApiClient.put(
                    "/v2/management/firmwares/" + id,
                    {
                        ...deviceGroup,
                        firmware_id: undefined,
                        name: name,
                        children: [child.value.firmware_id],
                    },
                    {
                        Authorization: "Bearer " + token,
                    }
                )
            )
            .then(() => {
                setEditing(false)
                loadDeviceGroup()
            })
            .catch((e) => {
                addMessage(
                    "Failed to update device group",
                    "Something went wrong while updating the device group, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })

    const remove = () =>
        getAccessTokenSilently()
            .then((token) =>
                ApiClient.delete("/v2/management/firmwares/" + id, {
                    Authorization: "Bearer " + token,
                })
            )
            .then(() => navigate("/devicegroup"))
            .catch((e) => {
                if (e.code === "has_parent") {
                    setErrorMsg(
                        "This device group cannot be removed" +
                            " because there are still other device groups referencing it."
                    )
                } else if (e.code === "has_linked_devices") {
                    setErrorMsg(
                        "This device group cannot be removed" +
                            " because it still contains devices."
                    )
                } else {
                    addMessage(
                        "Failed to remove device group",
                        "Something went wrong while removing the device group, " +
                            "see the console for more details.",
                        MessageType.error,
                        e
                    )
                }
            })

    const getFirmwareIdentifier = (fw) => {
        if (!fw || !fw.children || fw.children.length === 0) {
            return "..."
        }

        return fw?.children[0]?.name || fw?.children[0]?.version
    }

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>Firmware ID</Form.Label>
                <Form.Control
                    readOnly
                    disabled
                    type="text"
                    value={deviceGroup?.firmware_id || "..."}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Device type</Form.Label>
                <Form.Control
                    readOnly
                    disabled
                    type="text"
                    value={deviceGroup?.device_type || "..."}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    disabled={!editing}
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Direct child device group or firmware version</Form.Label>
                {!editing ? (
                    <Form.Control disabled value={getFirmwareIdentifier(deviceGroup)} />
                ) : (
                    <FirmwareSelector
                        value={child}
                        setValue={setChild}
                        currentFirmwareId={deviceGroup.firmware_id}
                        onlyDeviceOfType={deviceGroup.device_type}
                    />
                )}
            </Form.Group>
            <div className="d-flex justify-content-end">
                {editing ? (
                    <ButtonGroup>
                        <Button
                            variant="light"
                            onClick={() => {
                                const child = deviceGroup.children[0]

                                setEditing(false)
                                setName(deviceGroup.name)
                                setChild({
                                    label: child.name || child.version,
                                    value: child,
                                })
                            }}
                        >
                            Cancel
                        </Button>
                        <Button disabled={name === ""} variant="success" onClick={update}>
                            Save
                        </Button>
                    </ButtonGroup>
                ) : (
                    <Button
                        disabled={
                            deviceGroup?.name === undefined || deviceGroup?.children === undefined
                        }
                        onClick={() => setEditing(true)}
                    >
                        Edit
                    </Button>
                )}
                <ConfirmButton
                    variant="danger"
                    generalClassName="ms-3"
                    disabled={
                        deviceGroup?.name === undefined || deviceGroup?.children === undefined
                    }
                    onConfirm={() => remove()}
                >
                    Remove
                </ConfirmButton>
            </div>
            <div className="text-danger text-end">{errorMsg}</div>
            <hr />
            <h4>Device groups & firmware version</h4>
            <FirmwareChain firmware={firmware} doneLoading={deviceGroup?.children !== undefined} />
        </>
    )
}
