import { FirmwareUriInput } from "../../components/Firmware/FirmwareUriInput"
import { FirmwareVersionInput } from "../../components/Firmware/FirmwareVersionInput"
import { Button, Col, Form } from "react-bootstrap"
import React, { useState } from "react"
import { ApiClient } from "../../ApiClient"
import { MessageType, useMessageReport } from "../../components/MessageReporter"
import { useAuth0 } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom"

export const FirmwareVersionCreate = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()
    const navigate = useNavigate()

    const [version, setVersion] = useState("")
    const [firmware, setFirmware] = useState({
        device: "",
        uri: "",
    })
    const formValid = version !== "" && firmware.device !== "" && firmware.uri !== ""

    const create = () => {
        const body = {
            type: "VERSION",
            version: version,
            device_type: firmware.device,
            uri: firmware.uri,
        }

        getAccessTokenSilently()
            .then((token) =>
                ApiClient.post("/v2/management/firmwares", body, {
                    Authorization: "Bearer " + token,
                })
            )
            .then(({ headers }) => navigate("/firmware/" + headers.location))
            .catch((e) => {
                addMessage(
                    "Failed to add firmware",
                    "Something went wrong while updating the firmware, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })
    }

    return (
        <Col md={12}>
            <h2>Add firmware version</h2>
            <Form.Group className="mb-3">
                <Form.Label>Version</Form.Label>
                <FirmwareVersionInput setVersion={setVersion} />
            </Form.Group>

            <FirmwareUriInput setFirmware={setFirmware} />

            <Button disabled={!formValid} onClick={() => create()}>
                Create
            </Button>
        </Col>
    )
}
