import { FirmwareVersionInput } from "./FirmwareVersionInput"
import { Button, Col, Form } from "react-bootstrap"
import React, { useState } from "react"
import { ApiClient } from "../../ApiClient"
import { MessageType, useMessageReport } from "../MessageReporter"
import { useAuth0 } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom"
import { FirmwareSelector } from "./FirmwareSelector"

export const FirmwareSetCreate = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()
    const navigate = useNavigate()

    const [version, setVersion] = useState("")
    const [entries, setEntriesRaw] = useState([])
    const setEntries = (newEntries) => {
        const entriesMap = new Map(newEntries.map((child) => [child.value.board_type, child]))
        setEntriesRaw(Array.from(entriesMap.values()))
    }

    const sameDeviceType = new Set(entries.map((e) => e.value.device_type)).size <= 1
    const formValid = version !== "" && Object.keys(entries).length > 1 && sameDeviceType

    const create = () => {
        const body = {
            type: "SET",
            version: version,
            device_type: entries[0].value.device_type,
            children: entries.map((entry) => entry.value.firmware_id),
        }

        getAccessTokenSilently()
            .then((token) =>
                ApiClient.post("/v2/management/firmwares", body, {
                    Authorization: "Bearer " + token,
                })
            )
            .then(({ headers }) => navigate("/firmware/" + headers.location))
            .catch((e) => {
                addMessage(
                    "Failed to add firmware",
                    "Something went wrong while updating the firmware, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })
    }

    return (
        <Col md={12}>
            <h2>Add firmware set</h2>
            <Form.Group className="mb-3">
                <Form.Label>Version</Form.Label>
                <FirmwareVersionInput setVersion={setVersion} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Firmware entries for this set</Form.Label>
                <FirmwareSelector
                    isMulti={true}
                    value={entries}
                    setValue={setEntries}
                    firmwareType="BOARDVERSION"
                    onlyFirmwareOfType={true}
                />
            </Form.Group>

            <Button disabled={!formValid} onClick={create}>
                Create
            </Button>
        </Col>
    )
}
