import { useAuth0 } from "@auth0/auth0-react"
import React, { useEffect, useState } from "react"
import { ApiClient } from "../../ApiClient"
import { Col, FormGroup, Form, FormSelect, Row, Button, Spinner } from "react-bootstrap"
import { MessageType, useMessageReport } from "../MessageReporter"

export const RemoteLogging = ({ deviceId }) => {
    const { getAccessTokenSilently } = useAuth0()

    const [remoteLoggingEnabled, setRemoteLoggingEnabled] = useState(false)
    const [logContext, setLogContext] = useState("")
    const [logLevel, setLogLevel] = useState("")

    const [showRemoteLogging, setShowRemoteLogging] = useState(false)
    const { addMessage } = useMessageReport()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!deviceId) {
            return
        }

        getAccessTokenSilently()
            .then((token) =>
                ApiClient.get(`/v2/consumer/devices/${deviceId}/config?filter=remote_logging`, {
                    Authorization: "Bearer " + token,
                })
            )
            .then(({ data }) => {
                setShowRemoteLogging(true)
                setRemoteLoggingEnabled(data.remote_logging.enabled)
                setLoading(false)
            })
            .catch((err) => {
                setShowRemoteLogging(false)
                setLoading(false)

                if (err && err.code !== "not_supported") {
                    addMessage(
                        "Remote logging",
                        "Could not get remote logging settings",
                        MessageType.error,
                        err
                    )
                }
            })
    }, [getAccessTokenSilently, deviceId, addMessage])

    const updateRemoteLoggingSettings = () => {
        getAccessTokenSilently()
            .then((token) =>
                ApiClient.patch(
                    `/v2/consumer/devices/${deviceId}/config`,
                    {
                        remote_logging: {
                            enabled: remoteLoggingEnabled,
                            context_name: logContext,
                            level: logLevel,
                        },
                    },
                    {
                        Authorization: "Bearer " + token,
                    }
                ).then(() => {
                    addMessage(
                        "Remote logging",
                        "Succesfully updated remote logging",
                        MessageType.success
                    )
                })
            )
            .catch((err) => {
                addMessage(
                    "Remote logging",
                    "Could not update remote logging",
                    MessageType.error,
                    err
                )
            })
    }

    return (
        <>
            {loading && <Spinner className="mb-3" variant="primary" />}
            {!loading && showRemoteLogging && (
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <Form.Label>Log context</Form.Label>
                            <Form.Control
                                onChange={(e) => {
                                    setLogContext(e.target.value)
                                }}
                                placeholder="Context"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <Form.Label>Log level</Form.Label>
                            <FormGroup>
                                <FormSelect
                                    onChange={(e) => {
                                        setLogLevel(e.target.value)
                                    }}
                                >
                                    <option value="">No log level selected</option>
                                    <option value="NONE">None</option>
                                    <option value="ERROR">Error</option>
                                    <option value="WARNING">Warning</option>
                                    <option value="INFO">Info</option>
                                    <option value="DEBUG">Debug</option>
                                    <option value="VERBOSE">Verbose</option>
                                </FormSelect>
                            </FormGroup>
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <Form.Label>Remote logging</Form.Label>
                            <Form.Check
                                defaultChecked={remoteLoggingEnabled}
                                type="switch"
                                onChange={(e) => {
                                    setRemoteLoggingEnabled(e.target.checked)
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <Button className="mt-4" onClick={updateRemoteLoggingSettings}>
                            Save
                        </Button>
                    </Col>
                </Row>
            )}
            {!loading && !showRemoteLogging && <span>Not supported on this device type.</span>}
        </>
    )
}
