import React, { useCallback, useContext, useEffect, useState } from "react"

import {
    startOfDay,
    endOfDay,
    startOfYear,
    endOfYear,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    sub,
    isValid,
    isSameDay,
    fromUnixTime,
    millisecondsToSeconds,
    format,
    parseISO,
    formatISO,
} from "date-fns"
import { useDebouncedCallback } from "use-debounce"
import { downloadCSVFile } from "csv-exporter-utility"
import { useSearchParams } from "react-router-dom"

const defaultPeriod = "24 hours"
const defaultResolution = "automatic"

export const resolutions = ["automatic", "original", "minute", "hour", "day"]

export const periodMap = {
    "24 hours": {
        begin: startOfDay,
        end: endOfDay,
        duration: { days: 1 },
    },
    "7 days": {
        begin: (t) => startOfWeek(t, { weekStartsOn: 1 }),
        end: (t) => endOfWeek(t, { weekStartsOn: 1 }),
        duration: { weeks: 1 },
    },
    "1 month": {
        begin: startOfMonth,
        end: endOfMonth,
        duration: { months: 1 },
    },
    "3 months": {
        begin: (t) => startOfMonth(sub(t, { months: 3 })),
        end: endOfMonth,
        duration: { months: 3 },
    },
    "1 year": {
        begin: startOfYear,
        end: endOfYear,
        duration: { years: 1 },
    },
}

const HistoryContext = React.createContext({
    data: undefined,
    start: undefined,
    end: undefined,
    period: undefined,
    uiDate: undefined,
    resolution: undefined,
    setResolution: () => {},
    setUiDate: () => {},
    setIsZoomed: () => {},
    setPeriod: () => {},
    afterZoom: () => {},
    addGraphData: () => {},
    exportGraph: () => {},
})

export const HistoryContextProvider = ({ children }) => {
    const [now, setNow] = useState(new Date())

    const [searchParams, setSearchParams] = useSearchParams()

    const [resolution, setResolution] = useState(searchParams.get("res") || defaultResolution)
    const [period, setPeriod] = useState(searchParams.get("period") || defaultPeriod)
    const [uiDate, setUiDate] = useState(
        searchParams.get("date") ? parseISO(searchParams.get("date")) : now
    )
    const [isZoomed, setIsZoomedRaw] = useState(searchParams.get("zoomed") || false)

    const setIsZoomed = useCallback((value) => {
        setNow(new Date())
        setIsZoomedRaw(value)
    }, [])

    const periodData = periodMap[period]

    // The internal representation is debounced and is always valid
    const [internalDate, setInternalDate] = useState(uiDate)
    const debouncedSetInternalDate = useDebouncedCallback(setInternalDate, 300)
    if (isValid(uiDate)) {
        debouncedSetInternalDate(uiDate)
    }

    // Used when not zoomed
    let toDate
    let fromDate
    if (isSameDay(internalDate, now)) {
        toDate = now
        fromDate = sub(now, periodData.duration)
    } else {
        toDate = periodData.end(internalDate)
        fromDate = periodData.begin(internalDate)
    }

    // Used when zoomed
    const [fromRange, setFromRange] = useState(
        searchParams.get("from") ? parseISO(searchParams.get("from")) : fromDate
    )
    const [toRange, setToRange] = useState(
        searchParams.get("to") ? parseISO(searchParams.get("to")) : toDate
    )

    // Real values
    const start = isZoomed ? fromRange : fromDate
    const end = isZoomed ? toRange : toDate

    const afterZoom = (min, max) => {
        setToRange(fromUnixTime(millisecondsToSeconds(max)))
        setFromRange(fromUnixTime(millisecondsToSeconds(min)))
        setIsZoomed(true)
    }

    const [graphData, setGraphData] = useState({})

    const addGraphData = useCallback(
        (key, param, data) =>
            setGraphData((oldGraphData) => {
                const old = oldGraphData[key]
                return { ...oldGraphData, [key]: { ...old, [param]: data } }
            }),
        []
    )

    const exportGraph = () => {
        Object.keys(graphData).forEach((dataSn) => {
            let completeDeviceData = []

            Object.keys(graphData[dataSn]).forEach((dataKey) => {
                completeDeviceData = [...completeDeviceData, ...graphData[dataSn][dataKey]]
            })

            downloadCSVFile(
                completeDeviceData,
                dataSn + "-export-" + format(new Date(), "dd-MM-yyyy-HH:mm")
            )
        })
    }

    useEffect(() => {
        const newParams = new URLSearchParams(searchParams.toString())
        if (resolution === defaultResolution) {
            newParams.delete("res")
        } else {
            newParams.set("res", resolution)
        }

        if (period === defaultPeriod) {
            newParams.delete("period")
        } else {
            newParams.set("period", period)
        }

        if (internalDate === now) {
            newParams.delete("date")
        } else {
            newParams.set("date", formatISO(internalDate, { format: "basic" }))
        }

        if (isZoomed) {
            newParams.set("zoomed", "true")
            newParams.set("from", formatISO(fromRange, { format: "basic" }))
            newParams.set("to", formatISO(toRange, { format: "basic" }))
        } else {
            newParams.delete("zoomed")
            newParams.delete("from")
            newParams.delete("to")
        }

        if (newParams.toString() !== searchParams.toString()) {
            setSearchParams(newParams, { replace: true })
        }
    }, [
        searchParams,
        setSearchParams,
        resolution,
        period,
        internalDate,
        isZoomed,
        fromRange,
        toRange,
        now,
    ])

    return (
        <HistoryContext.Provider
            value={{
                start,
                end,
                period,
                uiDate,
                resolution,
                setResolution,
                afterZoom,
                setUiDate,
                setIsZoomed: setIsZoomed,
                setPeriod: setPeriod,
                exportGraph,
                addGraphData,
            }}
        >
            {children}
        </HistoryContext.Provider>
    )
}

export const useHistory = () => ({
    data: useContext(HistoryContext).data,
    start: useContext(HistoryContext).start,
    end: useContext(HistoryContext).end,
    period: useContext(HistoryContext).period,
    uiDate: useContext(HistoryContext).uiDate,
    resolution: useContext(HistoryContext).resolution,
    setResolution: useContext(HistoryContext).setResolution,
    setUiDate: useContext(HistoryContext).setUiDate,
    setIsZoomed: useContext(HistoryContext).setIsZoomed,
    setPeriod: useContext(HistoryContext).setPeriod,
    afterZoom: useContext(HistoryContext).afterZoom,
    addGraphData: useContext(HistoryContext).addGraphData,
    exportGraph: useContext(HistoryContext).exportGraph,
})
