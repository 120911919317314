import { useEffect, useState } from "react"
import { ApiClient } from "../../ApiClient"
import { useAuth0 } from "@auth0/auth0-react"
import { MessageType, useMessageReport } from "../MessageReporter"
import { InfoList } from "../InfoList"
import { formatDateTime } from "../../util/formatDataTime"
import { VentilationLevels } from "../../enums/VentilationLevels"

const latestValuesFilter = [
    "ambient_temperature",
    "ambient_humidity",
    "ambient_eco2",
    "ambient_offset",
    "boiler_control_mode",
    "room_setpoint",
    "weekschedule_state",
    "ventilation_level",
    "flame_state",
    "external_temperature_sensor_active",
]

export const DeviceLatestValues = ({ id }) => {
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()

    const [latestValues, setLatestValues] = useState({})

    useEffect(() => {
        getAccessTokenSilently()
            .then((token) =>
                ApiClient.get(`/v2/consumer/history/${id}/latest?filter=${latestValuesFilter}`, {
                    Authorization: "Bearer " + token,
                })
            )
            .then(({ data }) =>
                Object.entries(data)
                    .filter(
                        ([name, value]) =>
                            latestValuesFilter.includes(name) && value.data.length > 0
                    )
                    .map(dataMapper)
                    .reduce((map, row) => {
                        map[row.key] = row
                        return map
                    }, {})
            )
            .then(setLatestValues)
            .catch((e) => {
                addMessage(
                    "Failed to get latest values for device",
                    "Something went wrong while getting the latest values for device, " +
                        "see the console for more details.",
                    MessageType.error,
                    e
                )
            })
    }, [getAccessTokenSilently, id, addMessage])

    const Value = ({ name, prop }) => {
        const row = latestValues[prop]
        if (row === undefined) {
            // Don't show rows without data
            return
        }
        return (
            <InfoList.Item
                name={name}
                tooltip={formatDateTime(row.entry.timestamp)}
                value={
                    <span className="text-break">{(row.entry.value + ` ${row.unit}`).trim()}</span>
                }
            />
        )
    }

    return Object.entries(latestValues).length < 1 ? (
        <span>This device has not sent any data yet.</span>
    ) : (
        <InfoList>
            <Value name="Room setpoint" prop="room_setpoint" />
            <Value name="Ambient temperature" prop="ambient_temperature" />
            <Value name="Humidity" prop="ambient_humidity" />
            <Value name="eCO2" prop="ambient_eco2" />
            <Value name="Flame state" prop="flame_state" />
            <Value name="Weekschedule active" prop="weekschedule_state" />
            <Value name="Boiler control mode" prop="boiler_control_mode" />
            <Value name="Ambient offset" prop="ambient_offset" />
            <Value name="Ventilation level" prop="ventilation_level" />
            <Value name="External temp sensor active" prop="external_temperature_sensor_active" />
        </InfoList>
    )
}

const upperCaseFirst = (input) => {
    return input.charAt(0).toUpperCase() + input.substr(1).toLowerCase()
}

const MappedLevels = VentilationLevels.map((x) => x.toUpperCase())

const dataMapper = (value) => {
    const res = {
        key: value[0],
        entry: value[1].data[0],
        unit: value[1].unit,
    }

    // Convert booleans to 'True' and 'False' text.
    if (typeof res.entry.value === "boolean") {
        res.entry.value = res.entry.value ? "True" : "False"
    }

    // Map the ventilation level enum.
    if (res.key === "ventilation_level") {
        const value = res.entry.value
        const level = MappedLevels.indexOf(value) + 1
        res.entry.value = upperCaseFirst(`${res.entry.value} (${level})`)
    }

    switch (res.unit) {
        case "percent":
            res.unit = "%"
            break
        case "celsius":
            res.unit = "°C"
            break
        case "none":
            res.unit = ""
            break
        default:
            break
    }

    return res
}
