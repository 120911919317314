import { Col, Form, InputGroup, Row } from "react-bootstrap"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ConnectedIcon } from "../../components/ConnectedIcon"
import { DetailLink } from "../../components/Table/DetailLink"
import { GenericOverviewTable, ResultCount } from "../../components/Table/GenericOverview"
import { ReactSelect } from "../../components/ReactSelect"
import { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useDebounce } from "use-debounce"
import { ApiClient } from "../../ApiClient"
import { TableControls } from "../../components/Table/TableControls"
import { useMessageReport, MessageType } from "../../components/MessageReporter"
import { SystemStatusIcon } from "../../components/SystemStatusIcon"

const options = [
    "Eva",
    "Power module",
    "Joule stat",
    "Ben stat",
    "Modulair",
    "Spaarpomp",
    "Ben",
].map((d) => ({
    value: d,
    label: d,
}))

const pageSizes = [20, 50, 100]
const defaultPageSize = pageSizes[1]

const error = {
    title: "Failed to get systems",
    body: "Something went wrong while getting the systems, see the console for more details.",
}

export const SystemOverview = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()

    const [loading, setLoading] = useState(true)
    const [tableError, setTableError] = useState(false)

    const [pageSize, setPageSize] = useState(searchParams.get("size") || defaultPageSize)
    const [currentPage, setCurrentPage] = useState(searchParams.get("page") * 1 || 0)

    const [data, setData] = useState([])
    const [dataCount, setDataCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)

    const [deviceFilter, setDeviceFilter] = useState([])
    const [search, setSearch] = useState(searchParams.get("search") || "")
    const [searchText] = useDebounce(search, 500)

    // Hack to prevent setCurrentPage from overwriting the url parameter
    const [dont, setDont] = useState(true)
    useEffect(() => setDont(false), [])
    useEffect(() => {
        dont || setCurrentPage(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, deviceFilter])

    useEffect(() => {
        setLoading(true)

        getAccessTokenSilently()
            .then((token) =>
                ApiClient.get(
                    "/v2/consumer/systems" +
                        `?search=${searchText}` +
                        `&deviceNames=${deviceFilter}` +
                        `&size=${pageSize}` +
                        `&page=${currentPage}`,
                    {
                        Authorization: `Bearer ${token}`,
                    }
                )
            )
            .then(({ data }) => {
                if (data === undefined || data.total_count === undefined) {
                    return Promise.reject(data)
                }

                setData(data.systems)
                setDataCount(data.systems.length)
                setTotalCount(data.total_count)

                setTableError(false)
            })
            .catch((e) => {
                setData([])
                setTotalCount(0)
                setTableError(true)
                addMessage(error.title, error.body, MessageType.error, e)
            })
            .finally(() => setLoading(false))
    }, [getAccessTokenSilently, addMessage, pageSize, searchText, deviceFilter, currentPage])

    useEffect(() => {
        setSearchParams(
            (prev) => {
                if (searchText === "") {
                    prev.delete("search")
                } else {
                    prev.set("search", searchText)
                }
                if (deviceFilter.length === 0) {
                    prev.delete("devices")
                } else {
                    prev.set("devices", deviceFilter)
                }
                if (pageSize === defaultPageSize) {
                    prev.delete("size")
                } else {
                    prev.set("size", pageSize)
                }
                if (currentPage === 0) {
                    prev.delete("page")
                } else {
                    prev.set("page", currentPage)
                }
                return prev
            },
            { replace: true }
        )
    }, [setSearchParams, searchText, deviceFilter, pageSize, currentPage])

    return (
        <Row className="content-section">
            <Col>
                <h2 className="mb-3">Systems</h2>
                <InputGroup className="mb-3">
                    <Form.Control
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        type="text"
                        id="search-bar-input"
                    />
                    <ReactSelect
                        className="col-12 col-sm-4"
                        placeholder="Filter..."
                        onChange={(e) => setDeviceFilter(e.map((d) => d.value))}
                        isMulti
                        options={options}
                    />
                </InputGroup>

                <p className="mt-2">
                    <ResultCount
                        currentPage={currentPage}
                        pageSize={pageSize}
                        dataCount={dataCount}
                        totalCount={totalCount}
                    />
                </p>

                <GenericOverviewTable
                    data={data}
                    loading={loading}
                    tableError={tableError}
                    errorText={error}
                    table={{
                        header: [
                            <th>System ID</th>,
                            <th>Status</th>,
                            <th>Devices</th>,
                            <th></th>,
                            <th>Actions</th>,
                        ],
                        body: (value) => (
                            <tr
                                key={value.system_id}
                                onClick={() => navigate(`/system/${value.system_id}`)}
                            >
                                <td className="font-monospace">
                                    <abbr
                                        style={{
                                            cursor: "inherit",
                                            textDecorationLine: "none",
                                        }}
                                        title={value.system_id}
                                    >
                                        {value.system_id.slice(30, 36).toUpperCase()}
                                    </abbr>
                                </td>
                                <td>
                                    <SystemStatusIcon status={value.highest_error_level} />
                                </td>
                                <td>
                                    {value.devices.map((dev, i) => (
                                        <span key={(dev.sn, +i)}>
                                            <ConnectedIcon connected={dev.connected} />
                                            <img
                                                className="me-2"
                                                style={{
                                                    marginLeft: "-4px",
                                                    height: "25px",
                                                }}
                                                alt={dev.type}
                                                src={`/devices/${dev.type}.svg`}
                                            />
                                        </span>
                                    ))}
                                </td>
                                <td>
                                    <small>
                                        {value.devices.map((dev) => dev.display_name).join(", ") ||
                                            "No devices"}
                                    </small>
                                </td>
                                <td>
                                    <DetailLink to={`/system/${value.system_id}`} />
                                </td>
                            </tr>
                        ),
                    }}
                    emptyMsg="No systems found"
                />

                <TableControls
                    currentPage={currentPage}
                    totalCount={totalCount}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    pageSizes={pageSizes}
                    setPageSize={setPageSize}
                />
            </Col>
        </Row>
    )
}
