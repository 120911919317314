import { Form, Table } from "react-bootstrap"
import classNames from "classnames"
import { SortableTh } from "../SortableColumns"
import React, { cloneElement } from "react"
import { DetailLink } from "../../components/Table/DetailLink"

export const FirmwareBoardversions = ({ entries }) => {
    const table = {
        header: [
            <SortableTh column="board_type">Board type</SortableTh>,
            <SortableTh column="version">Version</SortableTh>,
            <th>Actions</th>,
        ],
        body: (value) => (
            <tr key={value.firmware_id}>
                <td>{value.board_type}</td>
                <td>{value.version}</td>
                <td>
                    <DetailLink to={`/firmware/${value.firmware_id}`} />
                </td>
            </tr>
        ),
    }

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>Entries</Form.Label>
            </Form.Group>
            <div
                // This hack triggers some css in _overview-table.scss. This is needed because we
                // can't directly style the div in the react-bootstrap's Table.
                className={classNames({ "one-child": entries.length === 1 })}
            >
                <Table responsive striped hover>
                    <thead className="thead-light">
                        <tr>
                            {table.header.map((elem, index) => cloneElement(elem, { key: index }))}
                        </tr>
                    </thead>
                    <tbody>{Object.entries(entries).map((value) => table.body(value[1]))}</tbody>
                </Table>
            </div>
        </>
    )
}
