import { Button, Form } from "react-bootstrap"
import { useHistory, periodMap, resolutions } from "../HistoryContext"
import { isValid, format, sub, add } from "date-fns"
import { settingFromPeriod } from "../Charts/ChartList"

export const DeviceHistoryControls = ({ allowResolutionSelection = false, ...props }) => {
    const {
        start,
        end,
        uiDate,
        setUiDate,
        period,
        setPeriod,
        resolution,
        setResolution,
        setIsZoomed,
        exportGraph,
    } = useHistory()

    const periodData = periodMap[period]

    const prev = (current, _period) => sub(current, _period.duration)
    const next = (current, _period) => add(current, _period.duration)

    return (
        <div {...props}>
            <div className="row">
                {allowResolutionSelection && (
                    <>
                        <div className="col-12">
                            <Form.Text muted>Resolution</Form.Text>
                        </div>
                        <div className=" col-xl-2 col-md-2 col-12">
                            <Form.Select
                                value={resolution}
                                onChange={(e) => {
                                    setResolution(e.target.value)
                                }}
                            >
                                {resolutions.map((res) => (
                                    <option key={res}>{res}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </>
                )}
                {!allowResolutionSelection && (
                    <div className=" col-xl-2 col-md-2 col-12">
                        <Form.Text muted>Resolution: </Form.Text>
                        {settingFromPeriod(start, end).resolution}
                    </div>
                )}

                <div
                    className="
            col-xl-6 offset-xl-1
            col-md-8
            col-12
            d-md-flex"
                    style={{ gap: "10px" }}
                >
                    <Button
                        variant="text"
                        onClick={() => {
                            setIsZoomed(false)
                            setUiDate(prev(uiDate, periodData))
                        }}
                    >
                        <i className="fa-solid fa-circle-arrow-left fa-xl"></i>
                    </Button>
                    <Form.Select
                        value={period}
                        onChange={(e) => {
                            setIsZoomed(false)
                            setPeriod(e.target.value)
                        }}
                    >
                        {Object.keys(periodMap).map((_period) => (
                            <option key={_period}>{_period}</option>
                        ))}
                    </Form.Select>
                    <Form.Control
                        type="date"
                        onChange={(e) => {
                            setUiDate(new Date(e.target.value))
                        }}
                        value={isValid(uiDate) ? format(uiDate, "yyyy-MM-dd") : ""}
                        isInvalid={!isValid(uiDate)}
                    />
                    <Button
                        variant="text"
                        onClick={() => {
                            setIsZoomed(false)
                            setUiDate(next(uiDate, periodData))
                        }}
                    >
                        <i className="fa-solid fa-circle-arrow-right fa-xl"></i>
                    </Button>
                </div>
                <div className="col-xl-3 col-md-2 col-12 text-end">
                    <Button variant="text" onClick={() => setIsZoomed(false)}>
                        <i className="fa-solid fa-magnifying-glass-chart fa-xl"></i>
                    </Button>
                    <Button variant="text" onClick={exportGraph}>
                        <i className="fa-solid fa-file-csv fa-xl"></i>
                    </Button>
                </div>
            </div>
        </div>
    )
}
