import { Dropdown } from "react-bootstrap"
import { Link } from "react-router-dom"

export const DetailLink = ({ to }) => (
    <div onClick={(e) => e.stopPropagation()}>
        <Dropdown>
            <Dropdown.Toggle variant="link" className="caret-off text-muted py-0">
                <i className="fas fa-ellipsis-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={to}>
                    Edit
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </div>
)
