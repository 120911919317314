import { OverlayTrigger, Table, Tooltip } from "react-bootstrap"

let InfoList = ({ children }) => (
    <Table>
        <tbody>{children}</tbody>
    </Table>
)

InfoList.Item = (props) => (
    <>
        <SmallScreenRow {...props} />
        <LargeScreenRow {...props} />
    </>
)

const SmallScreenRow = ({ name, value }) => (
    <tr className="d-md-none">
        <td>{name}</td>
        <td className="text-break">{value === undefined ? "..." : value}</td>
    </tr>
)

const LargeScreenRow = ({ name, value, tooltip }) => (
    <OverlayTrigger
        // Focus doesn't do anything on a `tr`, so is used here to disable the tooltip
        trigger={tooltip === undefined ? "focus" : "click"}
        placement="left"
        overlay={<Tooltip>{tooltip}</Tooltip>}
    >
        <tr className="d-none d-md-table-row">
            <td style={{ whiteSpace: "nowrap" }}>{name}</td>
            <td className="text-break w-100">{value === undefined ? "..." : value}</td>
        </tr>
    </OverlayTrigger>
)

export { InfoList }
