import { useRoleContext } from "../RoleContext"

export const RoleProtect = ({ children, allowedRoles }) => {
    const { role } = useRoleContext()

    if (allowedRoles && !allowedRoles.includes(role)) {
        return <></>
    }
    return children
}
