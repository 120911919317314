import Select from "react-select"
import classNamesFunc from "classnames"
import { createElement } from "react"

export const ReactSelect = ({ classNames, tag = Select, ...props }) =>
    createElement(tag, {
        // default label formatter
        formatOptionLabel: (option) => <span>{option.label}</span>,
        ...props,
        classNames: {
            control: (state) =>
                classNamesFunc("react-select-bootstrap-control", {
                    "react-select-bootstrap-control-focused": state.isFocused,
                    "react-select-bootstrap-control-disabled": state.isDisabled,
                }),
            singleValue: () => "text-muted",
            valueContainer: () => "react-select-value-container",
            dropdownIndicator: () => "react-select-bootstrap-control-arrow",
            menu: () => "react-select-dropdown-menu",
            menuList: () => "react-select-dropdown-menu-list",
            option: (state) =>
                classNamesFunc({
                    "react-select-option-highlight": state.isFocused,
                }),
            ...classNames,
        },
    })
