import React, { useState } from "react"
import { FirmwareVersionCreate } from "../../components/Firmware/FirmwareVersionCreate"
import { Col, Form, Row } from "react-bootstrap"
import { FirmwareBoardVersionCreate } from "../../components/Firmware/FirmwareBoardVersionCreate"
import { FirmwareSetCreate } from "../../components/Firmware/FirmwareSetCreate"

export const AddFirmware = () => {
    const [firmwareType, setFirmwareType] = useState("version")

    return (
        <Row className="content-section">
            <Col md={3} style={style.firmwareTypeContainer}>
                <Form.Group>
                    <Form.Label>Firmware type</Form.Label>
                    <Form.Select
                        onChange={(e) => setFirmwareType(e.target.value)}
                        value={firmwareType}
                    >
                        <option value="version">Firmware version</option>
                        <option value="set">Firmware set</option>
                        <option value="boardversion">Board version</option>
                    </Form.Select>
                </Form.Group>
            </Col>

            {firmwareType === "version" && <FirmwareVersionCreate />}
            {firmwareType === "set" && <FirmwareSetCreate />}
            {firmwareType === "boardversion" && <FirmwareBoardVersionCreate />}
        </Row>
    )
}

const style = {
    firmwareTypeContainer: {
        marginBottom: "40px",
    },
}
