import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

export const CreateAccount = () => (
    <div className="partial-background ms-0">
        <div className="m-3 m-lg-4">
            <main className="container" style={{ maxWidth: "800px" }}>
                <Row className="content-section">
                    <Col>
                        <h3>How to create an account</h3>
                        <ol>
                            <li className="mb-2">
                                Visit the <Link to="/">homepage</Link> of this website.
                            </li>
                            <li className="mb-2">You will be redirected to a login page.</li>
                            <li className="mb-2">
                                On the login page, click the "Sign up" link at the bottom of the
                                page.
                            </li>
                            <li className="mb-2">
                                After creating an account and logging in, you will see a page with
                                the message "You do not have access to this application."
                            </li>
                            <li className="mb-2">
                                Let your contact at Joule know you have created an account, and what
                                email you used when creating the account.
                            </li>
                            <li>You will be given the appropriate permissions.</li>
                        </ol>
                    </Col>
                </Row>
            </main>
        </div>
    </div>
)
