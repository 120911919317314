import { useRouteError } from "react-router-dom"

const Content = ({ error, page }) => (
    <>
        <h1>Something went wrong</h1>
        You can try reloading the page. <br />
        But first please report this as a bug, and include a screenshot.
        <hr />
        This is the <strong>{page}</strong>. <br />
        The error is:
        <pre>{error.stack}</pre>
        On the url:
        <pre>{window.location.href}</pre>
    </>
)

export const RouterErrorPage = () => {
    const error = useRouteError()
    return (
        <div className="p-5">
            <Content error={error} page="router error page" />
        </div>
    )
}

export const ContentErrorPage = ({ error }) => <Content error={error} page="content error page" />

export const GlobalErrorPage = ({ error }) => (
    <div className="p-5">
        <Content error={error} page="global error page" />
    </div>
)

export const Auth0ErrorPage = ({ error }) => (
    <div className="p-5">
        <Content error={error} page="auth0 error page" />
    </div>
)

export const EmailNotVerifiedErrorPage = ({ error }) => {
    return (
        <div className="partial-background ms-0">
            <div className="m-3 m-lg-4">
                <main className="container-lg">
                    <div className="content-section">
                        You will receive a verification email shortly.
                        <br />
                        To login you must verify your email address by clicking the link in that
                        email.
                        <br />
                        <br />
                        After your email address is verified please <a href="/">click here</a> to
                        continue.
                    </div>
                </main>
            </div>
        </div>
    )
}
