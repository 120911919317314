import React, { useContext, useEffect, useState } from "react"
import { ApiClient } from "../ApiClient"
import { useAuth0 } from "@auth0/auth0-react"
import { MessageType, useMessageReport } from "./MessageReporter"

const RoleContext = React.createContext({
    role: undefined,
})

export const useRoleContext = () => ({
    role: useContext(RoleContext).role,
})

export const RoleContextProvider = (props) => {
    const { getAccessTokenSilently, user } = useAuth0()
    const { addMessage } = useMessageReport()

    const [role, setRole] = useState({ role: undefined })

    useEffect(() => {
        if (user && !role.role) {
            getAccessTokenSilently()
                .then((token) =>
                    ApiClient.get("/v2/consumer/users/self", {
                        Authorization: "Bearer " + token,
                    })
                )
                .then((res) => setRole({ role: res.data.role }))
                .catch((err) =>
                    addMessage("User role", "Could not get user role", MessageType.error, err)
                )
        }
    }, [getAccessTokenSilently, addMessage, role, user])

    return <RoleContext.Provider value={role}>{props.children}</RoleContext.Provider>
}
