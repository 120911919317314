import { useAuth0 } from "@auth0/auth0-react"
import { StatisticChart } from "../../components/Statistics/StatisticChart"
import { useCallback, useEffect, useState } from "react"
import { ApiClient } from "../../ApiClient"
import { Col, Row, Tab, Tabs } from "react-bootstrap"
import { MessageType, useMessageReport } from "../../components/MessageReporter"

export const Home = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { addMessage } = useMessageReport()

    const [usageStatistics, setUsageStatistics] = useState([])
    const [deviceStatistics, setDeviceStatistics] = useState([])

    const [categorizedStatistics, setCategorizedStatistics] = useState({})

    useEffect(() => {
        getAccessTokenSilently()
            .then((token) =>
                ApiClient.get("/v2/management/statistics", {
                    Authorization: "Bearer " + token,
                })
            )
            .then((resp) => {
                setUsageStatistics(resp.data.usages)
                setDeviceStatistics(resp.data.metrics)
            })
            .catch((err) => {
                addMessage("Statistics", "Could not get cloud statistics", MessageType.error, err)
            })
    }, [getAccessTokenSilently, addMessage])

    const disconnectedColor = "#F44336" // Red

    const filterToLabel = (key) => key[0].toUpperCase() + key.slice(1).replaceAll("_", " ")
    const mapHistory = useCallback((res) => {
        const colors = [
            "#8BC34A", // Light Green
            "#4CAF50", // Green
            "#00BCD4", // Cyan
            "#03A9F4", // Light Blue
            "#2196F3", // Blue
            "#3F51B5", // Indigo
            "#9C27B0", // Purple
        ]

        let globalSets = []
        const categoryMap = {}

        res.forEach((entry) => {
            if (!categoryMap.hasOwnProperty(entry.name)) {
                categoryMap[entry.name] = []
            }
            categoryMap[entry.name].push({
                x: new Date(entry.time),
                y: entry.value !== undefined ? entry.value : 0,
            })
        })

        Object.entries(categoryMap).forEach(([key, values], index) => {
            globalSets.push({
                label: filterToLabel(key),
                data: values,
                backgroundColor: colors[index % colors.length],
                borderColor: colors[index % colors.length],
                borderWidth: 1,
                cubicInterpolationMode: "monotone",
                stepped: false,
            })
        })

        return {
            labels: [],
            datasets: globalSets,
        }
    }, [])

    useEffect(() => {
        let mappedStatistics = {}

        mapHistory(deviceStatistics).datasets.forEach((statistic) => {
            const matches = /Devices (dis)?connected (.*) (.*)/.exec(statistic.label)
            const category = matches[2]

            if (!mappedStatistics.hasOwnProperty(category)) {
                mappedStatistics[category] = []
            }

            if (matches[1] === "dis") {
                if (!mappedStatistics[category].hasOwnProperty("disconnected")) {
                    mappedStatistics[category].disconnected = []
                }

                for (let i = 0; i < statistic.data.length; i++) {
                    const existing = mappedStatistics[category].disconnected[i]
                    if (!existing) {
                        mappedStatistics[category].disconnected.push(statistic.data[i])
                    } else {
                        mappedStatistics[category].disconnected[i].y =
                            mappedStatistics[category].disconnected[i].y + statistic.data[i].y
                    }
                }
            } else {
                statistic.label = matches[3]
                mappedStatistics[category].push(statistic)
            }
        })

        Object.entries(mappedStatistics).forEach((cat) => {
            const copyData = mappedStatistics[cat[0]][0]

            if (!cat[1].disconnected) {
                cat[1].disconnected = copyData.data.map((x) => {
                    x.y = 0
                    return x
                })
            }

            mappedStatistics[cat[0]].push({
                label: "Disconnected",
                data: cat[1].disconnected,
                backgroundColor: disconnectedColor,
                borderColor: disconnectedColor,
                borderWidth: 1,
                stepped: false,
            })
        })

        setCategorizedStatistics(mappedStatistics)
    }, [deviceStatistics, mapHistory])

    return (
        <Row className="content-section">
            <Col xs={12}>
                <h2>Statistics </h2>
                <h3>Devices</h3>
                <Tabs justify>
                    {Object.entries(categorizedStatistics).map((x) => {
                        return (
                            <Tab key={x[0]} eventKey={x[0]} title={x[0]}>
                                <StatisticChart data={{ datasets: x[1] }} />
                            </Tab>
                        )
                    })}
                </Tabs>

                <hr style={{ marginBottom: 30, marginTop: 30 }}></hr>
                <h2>Users</h2>
                <StatisticChart data={mapHistory(usageStatistics)} />
            </Col>
        </Row>
    )
}
