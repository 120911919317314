import { Col, Row } from "react-bootstrap"
import { DeviceGroupEdit } from "../../components/DeviceGroup/DeviceGroupEdit"
import { DeviceFirmwareLink } from "../../components/Firmware/DeviceFirmwareLink"

export const DeviceGroupDetail = () => (
    <Row className="content-section">
        <Col>
            <h2>Device group</h2>
            <DeviceGroupEdit />
            <hr />
            <DeviceFirmwareLink />
        </Col>
    </Row>
)
